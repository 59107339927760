import axios from 'axios';
import { URL } from './config';

export const createWallet = (data: any) => {
  const requestURL = `${URL}/wallets`;
  return axios
    .post(requestURL, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const updateWallet = (idWallet: string, data: any) => {
  const requestURL = `${URL}/wallets/${idWallet}`;
  return axios
    .put(requestURL, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const removeWallet = (idWallet: string) => {
  const requestURL = `${URL}/wallets/${idWallet}`;
  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
