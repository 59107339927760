import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import QRcode from '../../assets/images/qr.png';
import Code128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import Aztec from '../../assets/images/aztec.png';
import {
  Barcode,
  BarcodeGroup,
  Title,
  Wrapper,
} from './barcodeSelection.styles';

type barcodeTypeSelectionProps = {
  setSelectedBarcode: (value: string) => void;
  barcodeType: string;
};

const BarcodeSelection: React.FC<barcodeTypeSelectionProps> = (props) => {
  const { setSelectedBarcode, barcodeType } = props;
  const intl = useIntl();

  return (
    <>
      <FormControlLabel
        defaultChecked
        control={
          <Checkbox
            value={barcodeType}
            onChange={(e) =>
              e.target.checked
                ? setSelectedBarcode('CODE128')
                : setSelectedBarcode('NONE')
            }
            checked={barcodeType !== 'NONE'}
          />
        }
        label={intl.formatMessage({
          id: 'barcode.checkbox.addBarcode',
        })}
      />
      {barcodeType !== 'NONE' && (
        <Wrapper>
          <Title>
            {intl.formatMessage({ id: 'common.label.barcodeSelect' })}
          </Title>
          <BarcodeGroup>
            <RadioGroup
              aria-label='subscripition Fields'
              name='communicationChannels'
              value={barcodeType}
              onChange={(e) => {
                setSelectedBarcode(e.target.value);
              }}
            >
              <Barcode>
                <FormControlLabel
                  value='CODE128'
                  control={<Radio />}
                  label='CODE 128'
                />
                <img className='imgRectangular' src={Code128} />
              </Barcode>
              <Barcode>
                <FormControlLabel
                  value='PDF417'
                  control={<Radio />}
                  label='PDF 417'
                />
                <img className='imgRectangular' src={PDF417} />
              </Barcode>
              <Barcode>
                <FormControlLabel
                  value='QR'
                  control={<Radio />}
                  label='QR Code'
                />
                <img className='imgSquare' src={QRcode} />
              </Barcode>
              <Barcode>
                <FormControlLabel
                  value='AZTEC'
                  control={<Radio />}
                  label='AZTEC'
                />
                <img className='imgSquare' src={Aztec} />
              </Barcode>
            </RadioGroup>
          </BarcodeGroup>
        </Wrapper>
      )}{' '}
    </>
  );
};

export default BarcodeSelection;
