import styled from 'styled-components';

export const Wrapper = styled('div')`
  .MuiInput-underline::before {
    display: none;
  }

  .section-title {
    font-weight: 500;
    font-size: 18px;
    font-family: Poppins;
    margin: 0;
    margin-bottom: 10px;
  }
`;
