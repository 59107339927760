import {
  NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from './types';
import { Dispatch } from 'redux';
import { updateDevicePushNotification } from '../apis/addDeviceIdApi';

export const managePushNotifcations = (
  key: string,
  action: string,
  deviceId: string
) => (dispatch: Dispatch) => {
  dispatch({
    type: NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  updateDevicePushNotification(key, action, deviceId).then((res) => {
    dispatch({
      type: NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
      prop: 'loading',
      value: false,
    });

    if (res.status === 200) {
      if (action === 'unsubscribe') {
        dispatch({
          type: NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
          prop: 'pushToken',
          value: '',
        });
      } else {
        dispatch({
          type: NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
          prop: 'pushToken',
          value: deviceId,
        });
      }
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'Vos préférences sont sauvegardés avec succès',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Erreur est survenue',
      });
    }
  });
};
export const notificationsPropsChanged = (prop: string, value: any): any => {
  return { type: NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED, prop, value };
};
