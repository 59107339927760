import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { resetPasswordStyles, useStyles } from './resetPassword.styles';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';

//Actions
import {
  resetPasswordverificationToken,
  resetPasswordPropsChanged,
  resetPasswordConfirm,
  resetPasswordIntialState,
} from '../../actions';

import logo from '../../assets/images/logo-BestWallet.png';
import { RootState } from '../../reducers';
import { ResetPasswordFormProps } from './resetPassword.types';
import { CircularProgress } from '@material-ui/core';

const ResetPassword: React.FC<ResetPasswordFormProps> = (props) => {
  const classes = useStyles();
  const {
    password,
    passwordConfirm,
    passwordError,
    passwordConfirmError,
    errorMessage,
    error,
    loading,
    loadingPage,
  } = props;

  // const token = getDataFromCache('token');

  // if (token) {
  //   return <Redirect to={{ pathname: '/wallets' }} />;
  // }

  React.useEffect(() => {
    const token = props.match.params.token;
    props.resetPasswordverificationToken(token);
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    props.resetPasswordIntialState();
    /* eslint-disable-next-line */
  }, []);

  // React.useEffect(() => {
  //   console.log('Unmount');
  //   return () => {
  //     props.resetPasswordIntialState();
  //   };
  // }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.resetPasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickResetPassword = () => {
    const token = props.match.params.token;
    props.resetPasswordConfirm(token, props, () =>
      props.history.push('/login')
    );
  };
  if (loadingPage) {
    return (
      <div className={classes.root}>
        <CircularProgress size={30} className={classes.circularProgress} />
      </div>
    );
  } else if (error) {
    return (
      <Wrapper>
        <span className='text-error-validation'>{error}</span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        <img src={logo} alt='logo' width='250' />

        <div className='div-inputs'>
          <InputLabel
            label='Nouveau mot de passe'
            name='password'
            value={password}
            error={passwordError}
            placeholder='Nouveau mot de passe ...'
            onChange={(e) => handleEventChange(e)}
          />

          <InputLabel
            label='Confirmez le mot de passe'
            name='passwordConfirm'
            value={passwordConfirm}
            error={passwordConfirmError}
            placeholder='Confirmez le mot de passe ...'
            onChange={(e) => handleEventChange(e)}
          />
        </div>

        {errorMessage && <span className='text-error'>{errorMessage}</span>}

        <Button
          styled='primary'
          onClick={handleClickResetPassword}
          margin='10px'
        >
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Confirmer'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => resetPasswordStyles(props)}
`;

const mapStateToProps = ({ resetPassword }: RootState) => {
  return {
    password: resetPassword.password,
    passwordConfirm: resetPassword.passwordConfirm,
    passwordError: resetPassword.passwordError,
    passwordConfirmError: resetPassword.passwordConfirmError,
    errorMessage: resetPassword.errorMessage,
    error: resetPassword.error,
    loading: resetPassword.loading,
    loadingPage: resetPassword.loadingPage,
  };
};

export const connector = connect(mapStateToProps, {
  resetPasswordverificationToken,
  resetPasswordPropsChanged,
  resetPasswordConfirm,
  resetPasswordIntialState,
});

export default connector(ResetPassword);
