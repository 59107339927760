import { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const walletDetailStyles = (props: { background?: string }) => {
  return css`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    /* min-height: calc(100vh - 60px); */

    .div-header {
      display: flex;
      align-items: center;
      gap: 20px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06);
      border-radius: 15px;
      padding-right: 20px;
      position: absolute;
      top: 30px;
      .wallet-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #828282;
      }

      .logo-container {
        width: 56px;
        height: 56px;
        border-radius: 15px;
        overflow: hidden;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .div-container {
      margin-top: 10px;
      .table-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #404040;
      }
    }
    .text-error {
      font-size: 24px;
      color: red;
    }
  `;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },

  circularProgress: {
    color: '#ff5078',
  },
}));
