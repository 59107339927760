import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { osName } from 'react-device-detect';

import { CustomerInscriptionState } from './customerInscription.types';
import styled from 'styled-components';

import SubscriptionPageApple from '../../components/subscriptionPageApple';
import LoadingIndicator from '../../components/LoadingIndicator';
import NotificationsRequest from '../NotificationsRequest';

const CustomerInscription: React.FC<any> = () => {
  const location = useLocation<CustomerInscriptionState>();
  let { appLinks, customerId, walletId } = location?.state;
  const [data, setData] = useState<any>();

  React.useEffect(() => {
    setData({
      links: appLinks,
      customer: { wallet: walletId, _id: customerId },
      kindId: null,
    });
  }, [appLinks, customerId, walletId]);

  const forwardLink = (link: any, osName: string) => {
    if (osName !== 'iOS' && osName !== 'Mac OS') {
      const googlePayLink = link.googlePayLink.slice(33);
      window.location.href =
        'https://pay.google.com/gp/v/a/save/' + googlePayLink;
    } else {
      window.location.href = link.applePayLink;
    }
  };

  return (
    <Container>
      {(osName == 'iOS' || osName == 'Mac OS') && (
        <SubscriptionPageApple forward={() => forwardLink(appLinks, osName)} />
      )}

      {data && osName !== 'iOS' && osName !== 'Mac OS' && (
        <NotificationsRequest
          data={data}
          forward={() => forwardLink(data.links, osName)}
        ></NotificationsRequest>
      )}
    </Container>
  );
};

const Container = styled('div')`
  iframe {
    height: 50px !important;
  }
  .errorMessage {
    color: red;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100vh;
  .MuiCircularProgress-root {
    margin-top: 43px;
  }
`;
export default CustomerInscription;
