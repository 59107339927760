export default {
  //**************************************Input File****************************//
  //Buttons
  'inputFile.button.select': 'Choisir une image',
  //**************************************Dialog****************************//
  //Buttons
  'dialog.button.agree': 'Oui',
  'dialog.button.desagree': 'Non',
  //**************************************DRAWER****************************//
  //Menu List
  'drawer.menuList.wallet': 'Wallet',
  'drawer.menuList.offers': 'Offre/Communication',
  'drawer.menuList.coupons': 'Coupons',
  'drawer.menuList.event': 'Evènements',
  'drawer.menuList.settings': 'Paramètres',
  'drawer.menuList.stats': 'Statistiques',

  //Menu Item
  'drawer.menuItem.profile': 'Profil',
  'drawer.menuItem.logout': 'Déconnexion',
  //**************************************Login****************************//
  //Labels
  'login.label.forgotPassword': 'Mot de passe oublié ?',
  //Placeholder
  'login.placeholder.username': 'Identifiant',
  'login.placeholder.password': 'Mot de passe',
  //Buttons
  'login.button.login': 'Connexion',
  //Error
  'login.error.fieldsReqiured': "L'identifiant et le mot de passe sont requis",
  'login.error.incorrectUsernameOrPassword':
    "L'identifiant ou le mot de passe est incorrect",
  'login.error.userNotAuthorized': 'Utilisateur non autorisé',
  //**************************************Settings****************************//
  //Settings Languages
  'settings.langauges.english': 'Anglais',
  'settings.langauges.french': 'Français',
  'settings.langauges.german': 'German',
  //Settings Labels
  'settings.label.settingsTitle': 'Paramètres',
  'settings.label.registrationParameterTitle': "Paramètre d'inscription",
  'settings.label.link': 'Lien:',
  'settings.label.QrCode': 'QR Code:',
  'settings.label.validationMessage':
    "Message de validation d'inscription de client externe",
  'settings.label.emailMessage': "Texte de l'email d'inscription",
  'settings.label.offerEmailMessage':
    "Texte de l'email d'offre (nouvelle offre)",
  'settings.label.offerNotificationMessage':
    'Texte de la notification (nouvelle offre)',
  'settings.label.couponEmailMessage': "Texte de l'email (nouveau coupon)",
  'settings.label.couponNotificationMessage':
    'Texte de la notification (nouveau coupon)',
  'settings.label.newOfferSMSMessage': "Texte de SMS d'offre (nouvelle offre)",

  'settings.label.couponSMSMessage': 'Texte de SMS (nouveau coupon)',
  'settings.label.changeLanguageTitle': 'Changer de langue',
  'settings.label.linkCopied': 'Lien copié',
  'settings.label.subscriptionMessage': "Texte d'abonnement",
  'settings.label.newAccountSMSMessage': "Message de SMS d'inscription",
  'settings.label.smsTitle': 'Titre du SMS',

  'settings.label.rewards': 'Gains',
  'settings.label.type': 'Type',
  'settings.label.fullName': 'Nom complet',
  'settings.label.text': 'Texte',
  'settings.label.disabled': 'Désactivé',
  'settings.label.creationDate': 'Date de création',

  'settings.label.headerFields': 'Champs d`en-tête (IOS)',
  'settings.label.firstField': 'Premier champ',
  'settings.label.secondField': 'Deuxième champ',
  'settings.label.personalizedText': 'Texte personnalisé',

  'settings.label.primaryFields': 'Champs principaux',

  //Placeholder
  'settings.placeholder.validationMessage': "Texte de validation d'inscription",
  'settings.placeholder.emailMessage': "Texte de l'email d'inscription",
  'settings.placeholder.offerEmailMessage':
    "Texte de l'email d'offre (nouvelle offre)",
  'settings.placeholder.offerNotificationMessage':
    'Vous avez une nouvelle offre,...',
  'settings.placeholder.couponEmailMessage': "Texte de l'email",
  'settings.placeholder.couponNotificationMessage': 'Texte de la notification',
  'settings.placeholder.couponSMSMessage': 'Texte de la notification SMS',
  'settings.placeholder.newOfferSMSMessage':
    "Texte de SMS d'offre (nouvelle offre)",

  'settings.placeholder.subscriptionMessage': "Votre Texte d'abonnement ici",
  'settings.placeholder.newAccountSMSMessage': "Texte de SMS d'inscription",

  //Settings Buttons
  'settings.button.save': 'Sauvegarder',
  'settings.button.cancel': 'Annuler',
  'settings.button.copyLink': 'Copier le lien',
  'settings.button.verifyEmail': 'Verifier Email',
  //**************************************ISSUING****************************//
  'issuing.label.title': "Paramètres d'émission",
  'issuing.checkbox.expirationDate': "Date d'expiration",
  'issuing.label.passWillExpire': 'Un pass va expirer',
  'issuing.button.afterPeriod': 'Après une période de',
  'issuing.button.atDateTime': 'A la date spécifiée',

  'issuing.select.weeks': 'Semaines',
  'issuing.select.months': 'Mois',
  'issuing.select.years': 'Ans',

  'issuing.checkbox.updateAll': 'Appliquer les mises à jour à tous les clients',

  //**************************************WALLET****************************//
  //Labels
  'wallet.label.searchCustomer': 'Rechercher un client',
  'wallet.label.filter': 'Filtrer par',
  'wallet.label.customersList': 'Liste des clients',
  'wallet.label.customersEmptyList': 'La liste des clients est vide',
  //Select
  'wallet.select.firstName': 'Prénom',
  'wallet.select.lastName': 'Nom',
  'wallet.select.email': 'Email',
  //Placeholder
  'wallet.placeholder.searchCustomer': 'Recherche',
  //Buttons
  'wallet.add': 'Ajouter Client',
  //**************************************Customers Table****************************//
  //Labels
  'customersTable.Barcode': 'Code-barres',
  'customersTable.id': 'ID',
  'customersTable.firstName': 'Prénom',
  'customersTable.lastName': 'Nom',
  'customersTable.createdAt': 'Enregistré le',
  'customersTable.gains': 'Gains',
  'customersTable.email': 'Email',
  'customersTable.phoneNumber': 'Numéro de téléphone',
  'customersTable.source': 'Source',
  'customersTable.source.internal': 'Interne',
  'customersTable.source.external': 'Externe',
  'customersTable.source.crm': 'CRM',
  'customersTable.source.imported': 'Importé',

  'customersTable.status': 'Statut',
  'customersTable.suprimer': 'Supprimé',
  'customersTable.system': 'Système',
  'customerTable.isDeleted': 'Oui',
  'customerTable.isNotDeleted': 'Non',
  //Buttons
  'customersTable.updateGain': 'Mettre à jour le gain',
  //Historical
  'customersTable.historical.title': 'Historique',
  'customersTable.historical.emptyList': "La liste d'historique est vide",
  'customersTable.historical.date': 'Date',
  'customersTable.historical.gain': 'Gain',
  //**************************************Update Gain****************************//
  //Labels
  'updateGain.label.gain': 'Mettre à jour le gains',
  //Placeholder
  'updateGain.placehoder.gain': 'Gains',
  //Buttons
  'updateGain.button.confirm': 'Mettre à jour',
  //Error
  'updateGain.error.gainNotNumber': 'Le gain doit être un nombre',
  //**************************************Add Customer****************************//
  //Labels
  'addCustomer.label.firstName': 'Prénom',
  'addCustomer.label.lastName': 'Nom',
  'addCustomer.label.email': 'Email',
  'addCustomer.label.barcode': 'Code à barres',
  'addCustomer.label.phoneNumber': 'Numéro de téléphone',
  //Placeholder
  'addCustomer.placeholder.firstName': 'Prénom',
  'addCustomer.placeholder.lastName': 'Nom',
  'addCustomer.placeholder.email': 'Email',
  'addCustomer.placeholder.barcode': 'Code à barres',
  'addCustomer.placeholder.phoneNumber': '+33757130710',
  //Buttons
  'addCustomer.button.add': 'Ajouter',
  //Error
  'addCustomer.error.firstNameRequired': 'Prénom est obligatoire',
  'addCustomer.error.lastNameRequired': 'Nom est obligatoire',
  'addCustomer.error.emailRequired': 'Email est obligatoire',
  'addCustomer.error.phoneRequired': 'Numéro de téléphone est obligatoire',
  'addCustomer.error.requiredFields':
    'Email ou le numéro de téléphone sont nécessaires',

  'addCustomer.error.emailInvalid': 'Email invalide',
  'addCustomer.error.emailExist': "L'e-mail ou le code-barres existe déjà",
  'addCustomer.error.phoneNumberInvalid': 'Numéro de téléphone est invalide',
  'addCustomer.error.barcodeInvalid':
    'le code à barre doit comporter au moins 8 caractères',
  'addCustomer.error.phoneNumberLength':
    'Le numéro de téléphone ne doit pas dépasser 15 chiffres ',
  //**************************************Offers List****************************//
  //Labels
  'offers.label.title': 'Liste des offres',
  'offers.label.emptyList': 'La liste des offres est vide',
  'offers.label.filter': 'Filtrer par',
  'offers.label.startDate': 'Date de début',
  'offers.label.endDate': 'Date de fin',
  'offers.label.allTime': 'Tout le temps',
  //Select
  'offers.select.all': 'TOUS',
  'offers.select.opened': 'OUVERT',
  'offers.select.actif': 'ACTIVÉ',
  'offers.select.inactif': 'DESACTIVÉ',
  'offers.select.expired': 'EXPIRÉ',
  'offers.select.deleted': 'SUPPRIME',

  //Dialog
  'offers.dialog.title': 'Information',
  'offers.dialog.text.inactif': 'Voulez-vous vraiment désactiver cette offre ?',
  'offers.dialog.text.action': '',
  'offers.dialog.text.deleted': 'Voulez-vous vraiment supprimer cette offre ?',

  //Buttons
  'offers.button.search': 'Rechercher',
  'offers.button.create': 'Nouvelle Offre',
  //**************************************Offer Card****************************//
  //Labels
  'offerCard.label.status': 'Status:',
  'offerCard.label.startDate': 'Date de début:',
  'offerCard.label.endDate': 'Date de fin:',
  'offerCard.label.description': 'Description',
  //Buttons
  'offerCard.button.desactivate': 'Désactiver',
  'offerCard.button.delete': 'Supprimer',
  //**************************************Statistiques****************************//
  //sms
  'stats.label.title': "Rapport d'envoi d'SMS",
  'stats.label.newAccount': 'Création de compte',
  'stats.label.offers': 'Les offres ',
  'stats.label.coupons': 'Coupons',
  'stats.label.events': 'Les événements',
  'stats.label.total': 'total',
  'stats.label.availableCredit': 'Crédits disponibles',
  'stats.label.noAssociatedAccount': "Aucun compte n'est associé ",
  'statsCLients.label.title': 'Rapport de statistiques du clients',

  'stats.label.flightsTickets': "Les tickets d'avions",
  'stats.label.walletUpdate': 'Mises a jour du wallet',
  'stats.label.moviesTickets': 'Les tickets de film',
  'stats.label.transitTickets': 'Les billets de voyage',
  'stats.label.reservations': 'Les réservations',
  'Rapport de statistiques du clients': 'Rapport de statistiques du clients',
  'stats.label.internal': 'Internes',
  'stats.label.external': 'Externes',
  'stats.label.activeClients': 'Clients actifs',
  'stats.label.inactiveClients': 'Clients inactifs',
  'stats.label.deletedClients': 'Clients Supprimé',
  'stats.label.androidActive': 'Actifs sur Android',
  'stats.label.iosActive': 'Actifs sur IOS',
  'stats.label.deletedAndroid': 'Supprimé sur Android',
  'stats.label.deletedIos': 'Supprimé sur iOS',
  'stats.label.emailReport': "Rapport de statistiques d'emails",
  'stats.label.totalClients': 'Total des clients',

  //**************************************Offer****************************//
  //Labels
  'offer.label.title': 'Créer une offre',
  'offer.label.image': 'Image à insérer (375px/123px)*',
  'offer.label.description': 'Description*',
  'offer.label.startDate': 'Date de début',
  'offer.label.endDate': 'Date de fin',
  //Placeholder
  'offer.placeholder.description': 'Description ...',
  //Buttons
  'offer.button.add': 'Ajouter',
  //Error
  'offer.error.imageRequired': "L'insertion d'image est obligatoire",
  'offer.error.startDateRequired': 'La date de début est obligatoire',
  'offer.error.startDateInvalid': 'Date de début non valide',
  'offer.error.endDateRequired': 'La date de fin est obligatoire',
  'offer.error.endDateInvalid': 'Date de fin non valide',
  'offer.error.endDateBeforeStartDate':
    'La date de fin est antérieure à la date de début',
  'offer.error.descriptionRequired': 'Description est obligatoire',
  'offer.error.datesUnavailable': 'Les dates ne sont pas disponibles',
  //**************************************Coupons List****************************//
  //Labels
  'coupons.label.title': 'Liste des coupons',
  'coupons.label.emptyList': 'La liste des coupons est vide',
  //Dialog
  'coupons.dialog.title': 'Information',
  'coupons.dialog.text': 'Voulez-vous envoyer ce coupon à tous les clients ?',
  //Buttons
  'coupons.button.create': 'Nouveau Coupon',
  //**************************************Coupon Card****************************//
  //Labels
  'couponCard.label.expirationDate': "Date d'expiration",
  'couponCard.label.percent': 'Pourcentage de réduction',
  'couponCard.label.promoCode': 'Code promo',
  'couponCard.label.description': 'Description',
  'couponCard.label.status': 'Statut',
  'couponCard.label.source': 'Source',
  //Buttons
  'couponCard.button.send': 'Envoyer',
  'couponCard.switch.activate': 'Activer',
  //**************************************Coupon****************************//
  //Labels
  'coupon.label.expirationDate': "Date d'expiration",
  'coupon.label.textColor': 'Couleur du titre (iOS)',
  'coupon.label.backgroundColor': "Couleur de l'arrière plan (iOS-Android)",
  'coupon.label.foregroundColor': 'Couleur du texte (iOS)',
  'coupon.label.image': 'Image à insérer (375px/98px)*',
  'coupon.label.percent': 'Pourcentage ou valeur*',
  'coupon.label.description': 'Description*',
  'coupon.label.promoCode': 'Promo Code*',
  'coupon.label.All': 'TOUT',
  'coupon.label.Internal': 'INTERNE',
  'coupon.label.External': 'EXTERNE',
  'coupon.label.crm': 'CRM',

  'coupon.label.Inactif': 'INACTIF',
  'coupon.label.Actif': 'ACTIF',
  'coupon.label.Expire': 'EXPIRÉ',
  //Placeholder
  'coupon.placeholder.description': 'Description ...',
  //Buttons
  'coupon.button.add': 'Ajouter',
  //Error
  'coupon.error.expirationDateRequired': "La date d'expiration est obligatoire",
  'coupon.error.expirationDateInvalid': "Date d'expiration non valide",
  'coupon.error.labelColorRequired': 'La couleur du texte est obligatoire',
  'coupon.error.backgroundColorRequired':
    "La couleur d'arrière-plan est obligatoire",
  'coupon.error.foregroundColorRequired':
    'La couleur de premier plan est obligatoire',
  'coupon.error.imageRequired': 'Image est obligatoire',
  'coupon.error.percentReductionRequired':
    'Pourcentage ou valeur de réduction est obligatoire',
  'coupon.error.percentReductionNotNumber': 'Le valeur doit être un nombre',

  'coupon.error.descriptionRequired': 'Description est obligatoire',
  'coupon.error.promoCodeRequired': 'Promo Code est obligatoire',

  //**************************************Events List****************************//
  //Labels
  'events.label.title': 'Liste des Evènements',
  'events.button.create': 'Nouvel évènement',
  'event.label.name': "Nom de l'évènement*",
  'event.label.openingDate': 'Date d’ouverture',
  'event.label.startingDate': 'Date de début',
  'event.label.closingDate': 'Date de fermeture',
  'event.label.eventLocation': 'Lieu de l’évènement ',
  'event.label.title': 'Créer un évènement',
  'event.label.image': 'Image à insérer (375px / 84px)*',
  'event.label.openingHour': 'Date et heure de debut de l’évènement',
  'event.label.closingHour': 'Date et heure de fin de l’évènement',
  'event.label.doorOpeningHour': 'Date et heure de l’ouverture des portes',
  'event.label.price': 'Prix de l’évènement',
  'event.label.locationName': "Nom d'emplacement*",
  'event.label.address': "Adresse d'emplacement*",
  'event.label.contactPhone': 'Téléphone de contact*',
  'event.label.email': 'Email de communication*',
  'event.label.website': 'Website*',
  'event.label.terms': 'Termes et conditions*',
  'event.label.labelColor': 'Couleur du titre (iOS)',
  'EventsCustomersTable.Id': 'ID (Ticket)',
  'EventsCustomersTable.firstName': 'Prénom',
  'EventsCustomersTable.lastName': 'Nom',
  'EventsCustomersTable.email': 'Email',
  'EventsCustomersTable.enregistre': 'Enregistré',
  'EventsCustomersTable.systeme': 'Système',
  'EventsCustomersTable.enregistre.true': 'Oui',
  'EventsCustomersTable.enregistre.false': 'Non',
  'event.label.section.style': "Style de l'événement",
  'event.label.section.timeLocation': "Heure et lieu de l'événement",
  'event.label.selectLocation': "Sélectionnez l'emplacement exact",
  'event.label.section.contact': 'Informations de contact',
  'event.label.subMessage': "Message d'abonnement*",
  'event.label.section.emailMessage': 'Message de l’email',
  'event.label.emailMessage': 'Message de l’email',
  'event.label.create': 'Créer',
  'event.label.section.info': 'Informations du l’événement',
  'email.label.emailMessage': "Message de l'événement",
  'mapComponent.label.search': 'Rechercher sur la carte',
  //Errors
  'event.error.nameRequired': "Nom de l'évènement est obligatoire",
  'event.error.priceRequired': "Prix de l'évènement est obligatoire",
  'event.error.locationNameRequired': 'Nom du location est obligatoire',
  'event.error.addressRequired': "address de l'évènement est obligatoire",
  'event.error.contactPhoneRequired': 'Telephone de contact est obligatoire',
  'event.error.emailRequired': 'Email de communication est obligatoire',
  'event.error.websiteError': 'Website est obligatoire',
  'event.error.termsRequired': 'Terms sont obligatoire',
  'event.error.imageRequiredRequired': 'Image de bande est obligatoire',
  'event.error.subscriptionMessageRequired':
    "Message d'abonnement est obligatoire",

  //**************************************Profile****************************//
  //Labels
  'profile.label.emailSettings': 'Paramètres Emailing',
  'profile.label.managerEmail': 'Email Principal',
  'profile.label.communicationEmail': 'Email de communication',
  'profile.label.communicationEmailHost': 'Serveur Hébergeur (Host)',
  'profile.label.communicationEmailPort': 'Numéro de Port',
  'profile.label.communicationEmailUser': "Nom d'utilisateur",
  'profile.label.communicationEmailPass': 'Mot de passe',
  'profile.label.emailSettingsConfirmation':
    'Veuillez saisir votre mot de passe',

  'profile.label.updatePasswordTitle': 'Changer le mot de passe',
  'profile.label.oldPassword': 'Ancien mot de passe',
  'profile.label.newPassword': 'Nouveau mot de passe',
  'profile.label.confirmPassword': 'Confirmer le mot de passe',
  //Placeholder
  'profile.placeholder.managerEmail': 'Votre Email ...',
  'profile.placeholder.communicationEmailHost': 'xx.xxx.xx ',
  'profile.placeholder.communicationEmailPort': '0000',
  'profile.placeholder.communicationEmailUser': "nom d'utilisateur ....",
  'profile.placeholder.communicationEmailPass': 'mot de passe ...',
  'profile.placeholder.oldPassword': 'Ancien mot de passe ...',
  'profile.placeholder.newPassword': 'Nouveau mot de passe ...',
  'profile.placeholder.confirmPassword': 'Confirmer le mot de passe ...',
  //Buttons
  'profile.button.confirmPassword': 'Confirmer',
  'profile.button.updateEmailSettings': 'Mettre à jour',
  'profile.button.updatePassword': 'Mettre à jour',
  //Success
  'profile.success.successMessage': 'Mis à jour avec succès',
  //Error
  'profile.error.oldPasswordRequired': 'Ancien mot de passe est obligatoire.',
  'profile.error.oldPasswordIncorrect':
    'Votre mot de passe actuel est incorrect.',
  'profile.error.newPasswordRequired': 'Nouveau mot de passe est obligatoire.',
  'profile.error.newPasswordRequiredLength':
    'Le mot de passe doit contenir au moins 4 caractères.',
  'profile.error.confirmPasswordRequired':
    'Confirm mot de passe est obligatoire.',
  'profile.error.confirmPasswordNotMatch':
    'La confirmation ne correspond pas au nouveau mot de passe.',
  'profile.error.emailSettingsError': 'Configuration Email est invalide',
  'profile.error.emailCommunication':
    'Erreur de configuration, veuillez vérifier les paramètres saisies.',
  'profile.error.userPasswordError': 'Mot de passe incorrect',
  'profile.error.managerEmail': 'Email Principal est obligatoire',
  'profile.error.userPrincipalEamilExistsError':
    'Adresse email déjà existante, essayez avec une autre',
  //**************************************Forgot Password****************************//
  //Labels
  'forgotPassword.label.email': "Saisissez votre nom d'utilisateur",
  //Placeholder
  'forgotPassword.placeholder.email': "Nom d'utilisateur",
  //Buttons
  'forgotPassword.button.confirm': 'Confirmer',
  //Error
  'forgotPassword.error.emailRequired': "Nom d'utilisateur est obligatoire",
  'forgotPassword.error.invalidEmail': "Nom d'utilisateur invalide",
  //**************************************Reset Password****************************//
  //Labels
  'resetPassword.label.password': 'Nouveau mot de passe:',
  'resetPassword.label.passwordConfirm': 'Confirmez le mot de passe:',
  //Placeholder
  'resetPassword.placeholder.password': 'Saisissez votre nouveau mot de passe',
  'resetPassword.placeholder.passwordConfirm': 'Confirmer votre mot de passe',
  //Buttons
  'resetPassword.button.confirm': 'Confirmer',
  //Error
  'resetPassword.error.passwordRequired':
    'Nouveau mot de passe est obligatoire.',
  'resetPassword.error.passwordRequiredLength':
    'Le mot de passe doit contenir au moins 4 caractères.',
  'resetPassword.error.passwordConfirmRequired':
    'Confirmer le mot de passe est obligatoire.',
  'resetPassword.error.passwordConfirmNotMatch':
    'La confirmation ne correspond pas au nouveau mot de passe.',
  'resetPassword.error.tokenExpired':
    'Désolé, votre token a expiré ! Vous devrez renvoyer votre e-mail.',

  'SubscriptionPageApple.header.first':
    "Votre carte s'afficher dans quelques seconds",
  'SubscriptionPageApple.header.second':
    'Installer Votre carte dans Apple Wallet en utilisant le bouton',
  'SubscriptionPageApple.header.third': ' en haut a droite de votre écran.',
  'SubscriptionPageApple.header.forth': "L'enregistrement n'a pas fonctionné ?",
  'SubscriptionPageApple.header.fifth':
    "Réafficher la carte pour pouvoir l'ajouter",
  'SubscriptionPageApple.header.add': ' AJOUTER ',

  //**************************************Notifications****************************//

  'notification.insctructions.title': 'Comment débloquer les notfications ?',
  'notification.insctructions.description': ` 1. Cliquez sur l'icône "Verrou" dans la barre d'adresse.
  <br /> 2. Tapez sur Permissions.
  <br /> 3. Trouvez la permission Notifications et autorisez-la.
  <br /> 4. Rafraîchissez la page`,
  'notification.insctructions.confirm': 'OK',

  'notification.page.title': "Autoriser l'envoi de notifications",
  'notification.page.description':
    "Nous n'enverrons que les offres les plus rentables ainsi que des informations sur le programme de fidélité.",

  'notification.page.showInsctructions': 'Les notifications sont bloquées?',

  'notification.page.buttonAllow': 'Autoriser les notifications',
  'notification.page.buttonSkip': "N'activez pas",

  'notification.page.redirecting': 'Redirection...',
  'notification.page.blocked': 'Les notifications sont bloquées',

  'common.label.barcodeSelect': 'Code à barres',
  'barcode.checkbox.addBarcode': 'Ajouter un code à barres',
};
