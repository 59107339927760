import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from 'react-icons-kit';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { wallet, cog, graph } from 'react-icons-kit/entypo';
import logo from '../../assets/images/logo-bestwallet-symbole.png';
import logoText from '../../assets/images/logo-bestwallet-nom.png';
import { ic_account_circle } from 'react-icons-kit/md/ic_account_circle'; //styles
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { useStyles, Root } from './drawer.styles';
import Tooltip from '@material-ui/core/Tooltip';
import { getDataFromCache } from '../../utils/cache';

const MenuList = [
  // {
  //   text: 'Statistiques',
  //   path: 'statistics',
  //   icon: <StatisticsIcon />,
  // },
  {
    text: 'Wallets',
    path: 'wallets',
    icon: <Icon icon={wallet} size='26' />,
  },
  {
    text: 'Paramètres',
    path: 'settings',
    icon: <Icon icon={cog} size='26' />,
  },
  {
    text: 'Statistiques',
    path: 'statistics',
    icon: <Icon icon={graph} size='26' />,
  },
];

interface DrawerProps extends RouteComponentProps {
  handleClick?: () => void;
  handleClose?: () => void;
  handleLogout?: () => void;
  onClickMenu?: (path: string) => void;
  children?: React.ReactNode;
}

const SideBar: React.FC<DrawerProps> = (props) => {
  const currentPath = window.location.pathname;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const currentUser = getDataFromCache('user');
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    props.history.push('/login');
  };

  const onClickMenu = (path: string) => {
    props.history.push(`/${path}`);
  };

  return (
    <Root>
      <div className={classes.root}>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.logoContainer}>
              <img className={classes.logoSymbole} src={logo} alt='logo' />
              <img className={classes.logoText} src={logoText} alt='logo' />
            </div>
          </div>
          <List>
            {MenuList.map((item, index) => {
              const selected = item.path
                ? currentPath.includes(item.path)
                : false;

              return (
                <Tooltip placement='right' key={index} title={item.text} arrow>
                  <ListItem
                    key={index}
                    // selected={selected}
                    button
                    onClick={() => onClickMenu(item.path)}
                    className={classes.listItem}
                    // style={{
                    //   height: '57px',
                    //   background:
                    //     'linear-gradient(60deg,rgb(72 20 244) -30%,rgb(247 90 160) 50%,rgb(254 174 110) 130%)',
                    // }}
                  >
                    <ListItemIcon
                      className={
                        selected
                          ? classes.listItemIconSelected
                          : classes.listItemIcon
                      }
                    >
                      {item.icon}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.navToolbar}>
            <div className={classes.headerSettings}>
              <div className={classes.pageTitle}>
                {MenuList.map((item, index) => {
                  if (currentPath.includes(item.path)) {
                    return item.text;
                  }
                })}
              </div>
              <div
                className={classes.profile + ' ' + 'hoverable'}
                onClick={handleClick}
              >
                <div className={classes.profilePicture}>
                  <Icon
                    size={36}
                    icon={ic_account_circle}
                    style={{ color: '#ffffff' }}
                  />
                </div>
                <div className={classes.accountData}>
                  {currentUser.username}
                </div>
                <Icon
                  size={32}
                  icon={ic_keyboard_arrow_down}
                  style={{ color: '#4914F4' }}
                />
              </div>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
          {props.children}
        </main>
      </div>
    </Root>
  );
};

export default withRouter(SideBar);
