import { isEmpty, isEqual } from '../../utils/validations';
import { ResetPasswordState } from './resetPassword.types';

export const validateFieldsResetPassword = (state: ResetPasswordState) => {
  let newState: ResetPasswordState = Object.assign({}, state);

  if (isEmpty(newState.password)) {
    newState.passwordError = 'Nouveau mot de passe est obligatoire.';
  } else if (newState.password.length < 4) {
    newState.passwordError =
      'Le mot de passe doit contenir au moins 4 caractères.';
  } else {
    newState.passwordError = '';
  }

  if (isEmpty(newState.passwordConfirm)) {
    newState.passwordConfirmError = 'Confirm mot de passe est obligatoire.';
  } else if (!isEqual(newState.password, newState.passwordConfirm)) {
    newState.passwordConfirmError =
      'La confirmation ne correspond pas au nouveau mot de passe.';
  } else {
    newState.passwordConfirmError = '';
  }

  let valid = false;
  if (newState.passwordError === '' && newState.passwordConfirmError === '') {
    valid = true;
  }
  return { newState, valid };
};
