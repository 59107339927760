import { authentication } from '../apis/loginApi';
import {
  LOGIN_FORM_PROPS_CHANGED,
  LOGINING_SUCCESS,
  LOGINING_FAILED,
  LOGINING_INITIAL_STATE,
  AppThunk,
} from './types';

import { LoginPropsChangedAction } from '../modules/Login/login.types';
import { LoginApiResponse } from '../apis/ApiTypes';
import { setInStorage } from '../utils/cache';

export const loginPropsChanged = (
  prop: string,
  value: any
): LoginPropsChangedAction => {
  return { type: LOGIN_FORM_PROPS_CHANGED, prop, value };
};

export const onLogin = (
  username: string,
  password: string,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: LOGIN_FORM_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });
  authentication(username, password)
    .then((res: any) => {
      if (res.data && res.status === 401) {
        dispatch({
          type: LOGINING_FAILED,
          payload: "L'identifiant ou le mot de passe est incorrect",
        });
      } else if (res.data && res.status === 400) {
        dispatch({
          type: LOGINING_FAILED,
          payload: "L'identifiant et le mot de passe sont requis",
        });
      } else if (
        res.data &&
        res.data.user &&
        res.data.user.role === 'manager'
      ) {
        dispatch({
          type: LOGINING_FAILED,
          payload: 'Utilisateur non autorisé',
        });
      } else {
        setInStorage('token', res.token);
        setInStorage('user', res.data.user);
        dispatch({ type: LOGINING_SUCCESS, payload: res.data });
        callback();
        dispatch({
          type: LOGINING_INITIAL_STATE,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGINING_FAILED,
        payload: err.message,
      });
    });
};
