import {
  getWalletListCustomers,
  getCustomerHistoryList,
} from '../apis/walletDetailApi';
import {
  WALLET_DETAIL_GET_WALLET_DETAIL,
  WALLET_DETAIL_GET_WALLET_DETAIL_FAILED,
  WALLET_DETAIL_INITIAL_STATE,
  WALLET_DETAIL_GET_CUSTOMER_HISTORY,
  WALLET_DETAIL_LOADING_HISTORY,
} from './types';
import { Dispatch } from 'redux';
import { WalletDetailApiResponse, Customer } from '../apis/ApiTypes';

export const getCustomersByWallet = (
  walletID: string,
  page: number | string,
  size: number | string
) => (dispatch: Dispatch) => {
  // return (dispatch) => {
  dispatch({
    type: WALLET_DETAIL_INITIAL_STATE,
  });
  getWalletListCustomers(
    walletID,
    page,
    size,
    (error: any, res: WalletDetailApiResponse) => {
      console.log(res, error);
      if (error) {
        let message = '';
        if (error && error.response.status === 404) {
          message = 'Wallet est introuvable ';
        }
        dispatch({
          type: WALLET_DETAIL_GET_WALLET_DETAIL_FAILED,
          payload: message,
        });
      } else {
        if (res.response.data.length !== 0) {
          dispatch({
            type: WALLET_DETAIL_GET_WALLET_DETAIL,
            payload: {
              ...res.response.data[0],
              customers:
                res.response.data[0].customers &&
                res.response.data[0].customers.map((item: Customer) => ({
                  ...item,
                  history: [],
                  loading: false,
                })),
            },
            totalCustomersCount: res.totalCustomersCount,
          });
        } else {
          // let customers = []
          // let load = res
          dispatch({
            type: WALLET_DETAIL_GET_WALLET_DETAIL,
            payload: { ...res.response.data[0] },
          });
        }
      }
    }
  );
};

export const getCustomerHistory = (customerID: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: WALLET_DETAIL_LOADING_HISTORY,
    customerID,
    payload: true,
  });
  getCustomerHistoryList(customerID).then(
    (res) => {
      dispatch({
        type: WALLET_DETAIL_GET_CUSTOMER_HISTORY,
        payload: res.response.data[0].history,
        customerID,
      });
    },
    (err) => {
      dispatch({
        type: WALLET_DETAIL_LOADING_HISTORY,
        customerID,
        payload: false,
      });
    }
  );
};
