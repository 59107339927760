import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      margin: '20px 0',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
    },
    table: {
      minWidth: 650,
    },
    textEmpty: {
      fontSize: '18px',
      fontWeight: 500,
      margin: 0,
    },
    historyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px',
    },
    circularProgressStyle: {
      color: '#ff5078',
    },

    imgContainer: {
      width: '120px',
    },
    tableHeaderCell: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      paddingLeft: '0px',
      fontSize: '14px',
      color: '#AEAEAE',
    },
    tableContentCell: {
      fontFamily: 'Poppins',
      paddingLeft: '0px',
      fontSize: '14px',
      color: '#404040',
      // borderBottom: 'unset!important',
    },
    imgStyle: {
      width: '100%',
      height: '100%',
    },
    HistoryTitle: {
      fontFamily: 'Poppins',
      margin: '5px 0px',
      fontWeight: 500,
      fontSize: '14px',
      color: '#FF5078 !important',
    },
    historyRow: {
      width: '700px',
      marginBottom: '20px',
    },
    LastHistoryTableElement: {
      borderBottom: 'none !important',
    },
    historyTableBody: {},
    root: {
      '& > *': {
        borderBottom: 'unset !important',
      },
    },
  })
);
