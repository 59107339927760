import styled from 'styled-components';

export const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border-radius: 10px;
  .errorModalTitle {
    display: flex;
  }
  .radioGroup {
    flex-direction: row;
    margin: 12px;
  }

  .iosheaderSection > * .label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    margin-top: 12px;
  }
  .iosheaderSection > p {
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #828282;
  }

  .container {
    display: flex;
    .img-content {
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .wireframe {
      position: sticky;
      top: 0;
      height: 1000px;
      width: auto;
      border-radius: 10px;
    }
    img {
    }
  }
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;
    margin-bottom: 10px;
    align-items: center;
    grid-gap: 10px;
  }
  /* overflow: hidden; */
  .select {
    width: 100%;
    margin-bottom: 20px;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #828282;
    }
    .MuiInput-underline::before {
      border: none;
    }
    .MuiInput-underline::after {
      border: none;
    }
    .MuiInputBase-root {
      width: 100%;
      background-color: #f8f6fe;
      color: #404040;
      border: none;
      border-radius: 15px;
      padding: 12px 12px 10px 14px;
      .MuiSelect-icon {
        right: 10px;
      }
    }
    .MuiSelect-select:focus {
      background: unset;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: unset;
    }
  }
  .iosheaderSection > * .MuiInput-underline {
    height: 48px !important;
  }

  .back-bottom {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100px;
    margin-top: 20px;
  }
  .back-bottom > h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #ff5078;
  }
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
    /* box-shadow: 0px 1px 3px #eeee; */
  }
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    * {
      margin: 0;
    }
  }
  .form-container {
    display: flex;
    margin-right: 20px;
    flex: 1;
    gap: 20px;
    /* overflow: scroll; */
    /* overflow-x: hidden; */
    flex-direction: column;
  }
  .section {
    box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
    border-radius: 20px;
    padding: 30px;
  }
  .wireframe-section {
    padding: 0px;
    /* border-radius: 10px; */
    position: sticky;
    top: 0;
    height: 100vh;
    /* overflow: scroll; */
    width: 350px;
  }
  .form-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* justify-content: center; */
    align-items: center;
    padding-right: 8px;
  }
  .section-title {
    font-weight: 500;
    font-size: 18px;
    font-family: Poppins;
    margin: 0;
    margin-bottom: 10px;
  }
  .section-row {
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }
  .mediaChange {
    display: flex;
    gap: 30px;
    @media only screen and (max-width: 1600px) {
      flex-direction: column !important;
    }
  }
  .password-section {
    margin-bottom: -30px;
    .icon {
      position: relative;
      top: -52px;
      left: 320px;
      cursor: pointer;
      img {
        height: 18px;
        width: auto;
      }
    }
  }
  .form-footer {
    align-self: flex-end;
  }
  .additionalContact {
    width: 730px;
    .additionalContact-title {
      width: 730px;
      display: flex;
      justify-content: space-between;
      h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #828282;
      }
      span {
        display: flex;
        align-items: center;
        gap: 5px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #ff5078;
      }
      span:hover {
        cursor: pointer;
      }
    }
  }
  .section-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: 15px;
    * {
      margin: 0;
    }
    h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #828282;
    }
  }
  .description-title {
    width: 730px;
    display: flex;
    justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ff5078;
    }
    span:hover {
      cursor: pointer;
    }
  }
  .error-message {
    color: red;
  }
  .map-section {
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
  }
  .MapSearch {
    height: 32px;
    z-index: 99;
    margin-top: 15px;
  }
`;
