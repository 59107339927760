import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import WalletReducer from './walletReducer';
import WalletsReducer from './walletsReducer';
import WalletDetailReducer from './walletDetailReducer';
import AddCustomerReducer from './addCustomerReducer';
import SettingsReducer from './settingsReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import snackbarReducer from './snackbarReducer';
import statsReducer from './statsReducer';
import statsDetailReducer from './statsDetailReducer';
import notificationSettings from './notificationSettings';

const reducers = combineReducers({
  login: LoginReducer,
  wallet: WalletReducer,
  wallets: WalletsReducer,
  walletDetail: WalletDetailReducer,
  customer: AddCustomerReducer,
  settings: SettingsReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  snackbar: snackbarReducer,
  stats: statsReducer,
  statsDetails: statsDetailReducer,
  notificationSettings: notificationSettings,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
