import {
  ADD_CUSTOMER_PROPS_CHANGED,
  ADD_CUSTOMER_GET_WALLET_DETAIL,
  ADD_CUSTOMER_FORM_CUSTOMER,
  ADD_CUSTOMER_GET_WALLET_DETAIL_FAILED,
  ADD_CUSTOMER_INITIAL_STATE,
  WALLET_GET_WALLET_SUBSCRIPTION_SETTINGS,
} from '../actions/types';
import {
  AddCustomerState,
  AddCustomerActionTypes,
} from '../modules/AddCustomer/addCustomer.types';

const INITIAL_STATE: AddCustomerState = {
  wallet: null,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  barcode: '',
  //errors
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  phoneNumberError: '',
  barcodeError: '',
  emailBarcodeError: '',
  //Loading
  loading: false,
  walletLoading: true,
  error: '',
};

export default (state = INITIAL_STATE, action: AddCustomerActionTypes) => {
  switch (action.type) {
    case ADD_CUSTOMER_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case ADD_CUSTOMER_GET_WALLET_DETAIL:
      return { ...state, wallet: action.payload, walletLoading: false };

    case WALLET_GET_WALLET_SUBSCRIPTION_SETTINGS:
      return { ...state, [action.prop]: action.value };

    case ADD_CUSTOMER_GET_WALLET_DETAIL_FAILED:
      return { ...state, error: action.payload, walletLoading: false };
    case ADD_CUSTOMER_FORM_CUSTOMER:
      return { ...state, ...action.newProps, loading: false };

    case ADD_CUSTOMER_INITIAL_STATE:
      return {
        ...state,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        barcode: '',
      };

    default:
      return state;
  }
};
