import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyC7c8Szcc1P_efFVOs83KkXUYc7crsK6QI',
    authDomain: 'bestwallet-b9dd7.firebaseapp.com',
    projectId: 'bestwallet-b9dd7',
    storageBucket: 'bestwallet-b9dd7.appspot.com',
    messagingSenderId: '362452590989',
    appId: '1:362452590989:web:81a1ec68af50d1a25c5dc4',
    measurementId: 'G-J9Z2G5GG1V',
  });
};

export const askForPermissioToReceiveNotifications = async () => {
  const messaging = firebase.messaging();
  try {
    let token = await messaging.getToken({
      vapidKey:
        'BHLuPFh7GHJ4132HjeB0U5mkY3qKazPvlbbBpdUpF3Y_xJ_cB49PaYrg3TVrVRspPlnj3h-zFRXS1xDlaCs02xQ',
    });
    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};
