import React from 'react';
import { InputFileWrapper } from './inputFile.styles';

export type InputFileProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultImage: string;
  image: string | object;
  label: string;
  width?: number;
  height?: number;
  error: string;
};

const InputFile: React.FC<InputFileProps> = (props) => {
  const onClear = (event: any) => {
    event.target.value = null;
    // (event.target as HTMLInputElement).value = null;
  };

  const { onChange, defaultImage, image, label, width, height, error } = props;

  let picture = defaultImage;
  if (typeof image === 'string') {
    picture = image;
  } else if (image) {
    picture = URL.createObjectURL(image);
  }
  return (
    <InputFileWrapper width={width} height={height}>
      <div className='img-content'>
        <img src={picture} alt={label} />
      </div>
      <span className='logo-text'>{label}</span>
      <label className='input-label'>
        Parcourir
        <input
          id='inputFile'
          type='file'
          hidden
          onChange={onChange}
          onClick={(e) => onClear(e)}
          accept='image/gif, image/jpeg, image/png'
        />
      </label>
      <span className='text-error'>{error}</span>
    </InputFileWrapper>
  );
};

export default InputFile;
