import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';
import SideBar from '../../components/Drawer';
import { settingsStyles } from './settings.style';

import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import {
  settingsPropsChanged,
  settingsConfirmUpdatePassword,
  settingsInitialState,
} from '../../actions';

import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

import { RootState } from '../../reducers';
import { getDataFromCache, removeFromCache } from '../../utils/cache';

const Settings: React.FC<any> = (props) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    loading,
  } = props;
  const [showOldpassword, setshowOldpassword] = React.useState<boolean>(false);
  const [showNewPassword, setshowNewPassword] = React.useState<boolean>(false);
  const [showConfimNewPassword, setshowConfimNewPassword] = React.useState<
    boolean
  >(false);
  React.useEffect(() => {
    props.settingsInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.settingsPropsChanged(event.target.name, event.target.value);
  };

  const handleClickUpdatePassword = () => {
    const objectUser: any = getDataFromCache('user');
    props.settingsConfirmUpdatePassword(objectUser._id, props, async () => {
      await removeFromCache('token');
      await removeFromCache('user');
      props.history.push('/login');
    });
  };
  const userData: any = getDataFromCache('user');
  return (
    <SideBar>
      <Wrapper>
        <div className='settings-header'>
          <span className='settings-title'>Paramètres</span>
          <Button
            styled='primary'
            onClick={handleClickUpdatePassword}
            style={{ alignSelf: 'center' }}
          >
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'sauvegarder'
            )}
          </Button>
        </div>
        <div className='settings-container'>
          <div className='update-email-container'>
            <span className='update-email-title'>Email : {userData.email}</span>
          </div>
          <div className='update-pass-container'>
            <div className='update-pass'>
              <span className='update-pass-title'>Change mot de Passe :</span>
              <div className='password-section'>
                <InputLabel
                  className='input-label-style'
                  type={showOldpassword ? 'text' : 'password'}
                  label='Ancien mot de passe'
                  name='oldPassword'
                  value={oldPassword}
                  error={oldPasswordError}
                  placeholder='Ancien mot de passe ...'
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {showOldpassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setshowOldpassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setshowOldpassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
              <div className='password-section'>
                <InputLabel
                  className='input-label-style'
                  type={showNewPassword ? 'text' : 'password'}
                  label='Nouveau mot de passe'
                  name='newPassword'
                  value={newPassword}
                  error={newPasswordError}
                  placeholder='Nouveau mot de passe ...'
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {showNewPassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setshowNewPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setshowNewPassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
              <div className='password-section'>
                <InputLabel
                  className='input-label-style'
                  type={showConfimNewPassword ? 'text' : 'password'}
                  label='Confirmer le mot de passe'
                  name='confirmPassword'
                  value={confirmPassword}
                  error={confirmPasswordError}
                  placeholder='Confirmer le mot de passe ...'
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {showConfimNewPassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setshowConfimNewPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setshowConfimNewPassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${(props) => settingsStyles(props)}
`;

const mapStateToProps = ({ settings, login }: RootState) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    loading,
  } = settings;

  return {
    oldPassword,
    newPassword,
    confirmPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  settingsPropsChanged,
  settingsConfirmUpdatePassword,
  settingsInitialState,
});

export default connector(Settings);
