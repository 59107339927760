import React from 'react';
import SideBar from '../../components/Drawer';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import styled from 'styled-components';
//Material

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';

//Components

//Actions
import {
  getSMSstatistics,
  getEmailStatistics,
  statsPropsChanged,
  StatsFilterSMS,
  getClientstatistics,
  resetStats,
} from '../../actions';
//Api
import { RootState } from '../../reducers';
import Button from '../../commons/Button';
import Text from '../../commons/Text';
import { getDataFromCache } from '../../utils/cache';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { offersStyles, OffersStylesProps } from './stats.styles';
import StatsCard from '../../components/StatsCard';
import { smsStateError } from './stats.types';

import { StatsDetailsFilterSMS } from '../../actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './statsTable.styles';
import { CardActionArea, CircularProgress } from '@material-ui/core';
import emptyState from '../../assets/images/emptyState.svg';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const Statistics: React.FC<any> = (props) => {
  const intl = useIntl();
  const objectUser: any = getDataFromCache('user');
  const classes = useStyles();

  const {
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    actifAccounts,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    totalCustomers,
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    totalSMS,
    allTime,
    startDate,
    endDate,
    endDateError,
    creditsQuantity,
    loading,
    wallet,
    typeDetails,
    loadingDetails,
    type,
    loadingClientsStats,
    loadingClientsSMSStats,
    couponEmails,
    totalEmails,
    flightEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    loadingEmailStats,
    cinemaEmails,
    hotelEmails,
    transitEmails,
  } = props;
  const cards = [
    {
      title: intl.formatMessage({ id: 'stats.label.newAccount' }),
      count: sentMessagesForAccountCreation,
      type: 'newAccount',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.offers' }),
      count: sentMessagesForOffers,
      type: 'offer',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.events' }),
      count: sentMessagesForEvents,
      type: 'event',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.coupons' }),
      count: sentMessagesForCoupons,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.total' }),
      count: totalSMS,
      type: 'total',
    },
  ];

  const emailStats = [
    {
      title: intl.formatMessage({ id: 'stats.label.newAccount' }),
      count: newAccountsEmails,
      type: 'newAccount',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.offers' }),
      count: offersEmails,
      type: 'offer',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.events' }),
      count: eventsEmails,
      type: 'event',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.coupons' }),
      count: couponEmails,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.flightsTickets' }),
      count: flightEmails,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.walletUpdate' }),
      count: walletUpdateEmails,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.moviesTickets' }),
      count: cinemaEmails,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.transitTickets' }),
      count: transitEmails,
      type: 'coupon',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.reservations' }),
      count: hotelEmails,
      type: 'coupon',
    },

    {
      title: intl.formatMessage({ id: 'stats.label.total' }),
      count: totalEmails,
      type: 'total',
    },
  ];

  const clientsStats = [
    {
      title: intl.formatMessage({ id: 'stats.label.totalClients' }),
      count: totalCustomers,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.external' }),
      count: extrnalSource,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.internal' }),
      count: internalSource,
      type: 'client',
    },
    {
      title: 'CRM',
      count: crmSource,
      type: 'client',
    },

    {
      title: 'Clients inactifs',
      count: InactifAccounts,
      type: 'client',
    },
    {
      title: 'Clients actifs',
      count: actifAccounts,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.androidActive' }),
      count: actifAccountsAndroid,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.iosActive' }),
      count: actifAccountsiOS,
      type: 'client',
    },
    {
      title: 'Clients Supprimé',
      count: deletedAccountsAndroid + deletedAccountsiOS,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.deletedAndroid' }),
      count: deletedAccountsAndroid,
      type: 'client',
    },
    {
      title: intl.formatMessage({ id: 'stats.label.deletedIos' }),
      count: deletedAccountsiOS,
      type: 'client',
    },
  ];

  React.useEffect(() => {
    props.getSMSstatistics(props.match.params.walletId);
    props.getEmailStatistics(props.match.params.walletId);
    props.getClientstatistics(props.match.params.walletId);
    return () => {
      props.resetStats();
    };
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.statsPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: any | null) => {
    props.statsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.StatsFilterSMS(objectUser.wallet, allTime, startDate, endDate);
  };
  const getFormatedMessage = (prop: smsStateError) => {
    if (!prop) {
      return '';
    }

    return intl.formatMessage({ id: prop });
  };

  const renderDetails = () => {
    if (loadingDetails) {
      return (
        <div className={classes.historyContainer}>
          <CircularProgress
            size={30}
            className={classes.circularProgressStyle}
          />
        </div>
      );
    } else if (typeDetails.length === 0 && type && !loadingDetails) {
      return (
        <div className={classes.historyContainer}>
          <div className={classes.imgContainer}>
            <img
              src={emptyState}
              className={classes.imgStyle}
              alt='empty-state'
            />
          </div>
          <p className={classes.textEmpty}>La liste est vide</p>
        </div>
      );
    }
  };
  const renderStats = () => {
    return (
      <Wrapper loading={loading.toString()}>
        <div className='div-search'>
          <div className='div-search-inputs'>
            <div className='div-search-date'>
              <KeyboardDatePicker
                id='start-date'
                label={intl.formatMessage({ id: 'offers.label.startDate' })}
                format='DD/MM/yyyy'
                value={allTime === false ? startDate : null}
                onChange={handleDateChange('startDate')}
                inputVariant='outlined'
                style={{ width: '250px' }}
                disabled={allTime}
              />

              <KeyboardDatePicker
                id='end-date'
                label={intl.formatMessage({ id: 'offers.label.endDate' })}
                format='DD/MM/yyyy'
                inputVariant='outlined'
                value={allTime === false ? endDate : null}
                onChange={handleDateChange('endDate')}
                error={endDateError !== ''}
                helperText={getFormatedMessage(endDateError)}
                style={{ width: '250px' }}
                disabled={startDate === null ? true : false || allTime}
              />
            </div>
          </div>

          <Button
            background='#FF5078'
            onClick={HandleClickSearch}
            styled='primary'
          >
            {intl.formatMessage({ id: 'offers.button.search' })}
          </Button>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'offers.label.allTime' })}
        />

        <div className='div-header'>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'stats.label.title' })}
          </Text>
          {loadingClientsSMSStats && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}{' '}
          <div>
            {intl.formatMessage({ id: 'stats.label.availableCredit' })}:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {creditsQuantity > 0 ? creditsQuantity : 0}
            </span>
          </div>
        </div>

        <div className='div-list'>
          {cards.map((item, index) => (
            <StatsCard
              key={item.title + index}
              title={item.title}
              count={item.count}
              type={item.type}
              walletId={objectUser.wallet}
              startDate={startDate}
              endDate={endDate}
              allTime={allTime}
            ></StatsCard>
          ))}
          {typeDetails.length > 0 && (
            <TableContainer style={{ marginTop: 28 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ textTransform: 'capitalize' }}>
                        {type}
                      </span>{' '}
                      Id
                    </TableCell>
                    <TableCell align='right'> Customers contacted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeDetails.map((row: any) => (
                    <TableRow key={row._id}>
                      <TableCell component='th' scope='row'>
                        {row._id}
                      </TableCell>
                      <TableCell align='right'>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className='div-header' style={{ justifyContent: 'flex-start' }}>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'stats.label.emailReport' })}
          </Text>
          {loadingEmailStats && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}
        </div>

        <div className='div-list'>
          {emailStats.map((item, index) => (
            <StatsCard
              key={item.title + index}
              title={item.title}
              count={item.count}
              type={item.type}
              walletId={objectUser.wallet}
              startDate={startDate}
              endDate={endDate}
              allTime={allTime}
            ></StatsCard>
          ))}
          {typeDetails.length > 0 && (
            <TableContainer style={{ marginTop: 28 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ textTransform: 'capitalize' }}>
                        {type}
                      </span>{' '}
                      Id
                    </TableCell>
                    <TableCell align='right'> Customers contacted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeDetails.map((row: any) => (
                    <TableRow key={row._id}>
                      <TableCell component='th' scope='row'>
                        {row._id}
                      </TableCell>
                      <TableCell align='right'>{row.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className='div-header' style={{ justifyContent: 'flex-start' }}>
          <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
            {intl.formatMessage({ id: 'statsCLients.label.title' })}
          </Text>
          {loadingClientsStats == true && (
            <div className={classes.historyContainer}>
              <CircularProgress
                size={25}
                className={classes.circularProgressStyle}
              />
            </div>
          )}
        </div>
        <div>
          <div className='div-list'>
            {clientsStats.map((item, index) => (
              <StatsCard
                key={item.title + index}
                title={item.title}
                count={item.count}
                type={item.type}
                walletId={objectUser.wallet}
                startDate={startDate}
                endDate={endDate}
                allTime={allTime}
              ></StatsCard>
            ))}
          </div>
        </div>
      </Wrapper>
    );
  };

  return props.match.params.walletId ? (
    renderStats()
  ) : (
    <SideBar>{renderStats()}</SideBar>
  );
};

const Wrapper = styled('div')<OffersStylesProps>`
  ${(props) => offersStyles(props)}
`;

const mapStateToProps = ({ stats, statsDetails }: RootState) => {
  const {
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    actifAccounts,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    creditsQuantity,
    totalCustomers,
    totalSMS,
    allTime,
    startDate,
    endDate,
    endDateError,
    loading,
    loadingClientsStats,
    loadingClientsSMSStats,
    wallet,
    couponEmails,
    totalEmails,
    flightEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    cinemaEmails,
    hotelEmails,
    transitEmails,
    loadingEmailStats,
  } = stats;

  const { type, typeDetails, loadingDetails } = statsDetails;

  return {
    sentMessagesForCoupons,
    sentMessagesForOffers,
    sentMessagesForEvents,
    sentMessagesForAccountCreation,
    deletedAccountsiOS,
    deletedAccountsAndroid,
    actifAccountsiOS,
    actifAccountsAndroid,
    totalCustomers,
    actifAccounts,
    creditsQuantity,
    InactifAccounts,
    extrnalSource,
    internalSource,
    crmSource,
    totalSMS,
    allTime,
    startDate,
    endDate,
    endDateError,
    loading,
    loadingDetails,
    wallet,
    type,
    typeDetails,
    loadingClientsStats,
    loadingClientsSMSStats,
    couponEmails,
    totalEmails,
    flightEmails,
    cinemaEmails,
    hotelEmails,
    transitEmails,
    passwordResetEmails,
    eventsEmails,
    walletUpdateEmails,
    newAccountsEmails,
    offersEmails,
    loadingEmailStats,
  };
};

const mapDispatchToProps = {
  getSMSstatistics,
  resetStats,
  statsPropsChanged,
  StatsFilterSMS,
  StatsDetailsFilterSMS,
  getEmailStatistics,
  getClientstatistics,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Statistics);
