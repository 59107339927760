import {
  WALLETS_GET_LIST_WALLETS,
  WALLETS_ADD_WALLET_TO_LIST,
  WALLETS_UPDATE_WALLET_TO_LIST,
  WALLETS_DELETE_WALLET,
  WALLETS_DELETE_WALLET_LOADING,
} from '../actions/types';
import {
  WalletsActionTypes,
  WalletsState,
} from '../modules/Wallets/wallets.types';
import { WalletApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: WalletsState = {
  walletsList: [],
  loading: true,
};

export default (state = INITIAL_STATE, action: WalletsActionTypes) => {
  switch (action.type) {
    case WALLETS_DELETE_WALLET_LOADING: {
      return { ...state, loading: true };
    }

    case WALLETS_GET_LIST_WALLETS:
      return { ...state, walletsList: action.payload, loading: false };

    case WALLETS_ADD_WALLET_TO_LIST:
      return {
        ...state,
        walletsList: [...state.walletsList, ...[action.payload]],
      };
    case WALLETS_UPDATE_WALLET_TO_LIST: {
      const newWalletsList: Array<WalletApiResponse> = [];
      state.walletsList.forEach((item) => {
        if (item._id === action.payload._id) {
          newWalletsList.push(action.payload);
        } else {
          newWalletsList.push(item);
        }
      });
      return {
        ...state,
        walletsList: newWalletsList,
      };
    }

    case WALLETS_DELETE_WALLET:
      // filter
      var newWalletsList = state.walletsList.filter(function (item) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, walletsList: newWalletsList };
    default:
      return state;
  }
};
