import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 0px 100px; */
  .pageHeader {
    background: linear-gradient(265.47deg, #fc9877 3.98%, #ff5078 96.03%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding: 30px 0px;
    h3 {
      color: white;
      font-size: 26px;
      text-align: center;
    }
  }
  .pageContent {
    padding: 50px 150px;
    .section {
      margin-bottom: 40px;
      h4 {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .pageContent {
      padding: 50px 50px;
    }
  }
  @media screen and (max-width: 600px) {
    .pageHeader {
      h3 {
        font-size: 22px;
      }
    }
    .section {
      h4 {
        font-size: 18px !important;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;
