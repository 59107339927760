import React, { useState } from 'react';
import styled from 'styled-components';
import applePay from '../../assets/images/addToWalletIOS.svg';
import applePayUS from '../../assets/images/addToWalletIOS-US.svg';

import { subscriptoinPageAppleStyles } from './subscriptionPageApp.style';
import divider from '../../assets/images/divider.png';
import iosPassPreview from '../../assets/images/addToWalletIOS.png';
import iosPassPreviewUS from '../../assets/images/addToWalletIOS-US.png';

import { CircularProgress } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { getBrowserLanguage } from '../../utils/language';

type ModalProps = {
  forward: () => void;
};

const SubscriptionPageApple: React.FC<ModalProps> = ({ forward }) => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const intl = useIntl();

  const displayLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <Wrapper>
      <div className='content'>
        <div className='notice'>
          {intl.formatMessage({ id: 'SubscriptionPageApple.header.first' })}
        </div>
        <div className='divider'>
          <img src={divider}></img>
        </div>

        <div className='header'>
          {intl.formatMessage({ id: 'SubscriptionPageApple.header.second' })}
          <b>
            {' '}
            {intl.formatMessage({
              id: 'SubscriptionPageApple.header.add',
            })}{' '}
          </b>
          {intl.formatMessage({ id: 'SubscriptionPageApple.header.third' })}
        </div>
        <div className='image'>
          <img
            src={
              getBrowserLanguage() === 'en' ? iosPassPreviewUS : iosPassPreview
            }
            alt='wallet preview'
          />
        </div>
        <div>
          <h5>
            {' '}
            {intl.formatMessage({ id: 'SubscriptionPageApple.header.forth' })}
          </h5>
          <p>
            {' '}
            {intl.formatMessage({ id: 'SubscriptionPageApple.header.fifth' })}
          </p>
          {!loading ? (
            <img
              className='saveToWalletButton'
              onClick={() => {
                displayLoader();
                forward();
              }}
              src={getBrowserLanguage() === 'en' ? applePayUS : applePay}
              alt='Save to wallet'
            />
          ) : (
            <CircularProgress size={40} color='inherit' />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => subscriptoinPageAppleStyles()}
`;

export default SubscriptionPageApple;
