import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { loginStyles } from './login.styles';
//Commomns
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import ForgotPasswpordModal from '../../components/Modal';
import ForgotPassword from '../ForgotPassword';
//Actions
import { loginPropsChanged, onLogin } from '../../actions';

import logo from '../../assets/images/logo-BestWallet.png';
import { RootState } from '../../reducers';
import { LoginFormProps } from './login.types';
import { CircularProgress } from '@material-ui/core';
import { getDataFromCache } from '../../utils/cache';

import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

const Login: React.FC<LoginFormProps> = (props) => {
  const { username, password, error, loading } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  // const token = getDataFromCache('token');
  // if (token) {
  //   return <Redirect to={{ pathname: '/wallets' }} />;
  // }

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.loginPropsChanged(event.target.name, event.target.value);
  };

  const handleLogin = () => {
    if (username.trim() === '' && password.trim() === '') {
      props.loginPropsChanged(
        'error',
        "L'identifiant et le mot de passe sont requis"
      );
    } else {
      props.onLogin(username, password, () => props.history.push('/wallets'));
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleLogin();
    }
  };

  return (
    <Wrapper>
      <div>
        <img src={logo} alt='logo' width='250' />

        {/* <span className='login-text'>Login</span> */}
        <ForgotPasswpordModal
          // width={380}
          // height={300}
          // top='25%'
          open={open}
          onClose={() => setOpen(!open)}
        >
          <ForgotPassword onSaveChanged={() => setOpen(!open)}></ForgotPassword>
        </ForgotPasswpordModal>
        <div className='div-inputs'>
          <Input
            error={error}
            value={username}
            type='text'
            name='username'
            placeholder='Identifiant'
            onChange={(e) => handleEventChange(e)}
            onKeyPress={(e) => handleKeyPress(e)}
          />

          <Input
            error={error}
            value={password}
            type='password'
            name='password'
            placeholder='Mot de passe'
            onChange={(e) => handleEventChange(e)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <span onClick={() => setOpen(!open)} className='forgot-password-text'>
            Mot de passe oublié ?
          </span>
        </div>

        {error && <span className='login-text-error'>{error}</span>}
        {/* <span className='forgot-password-text'>Forgot Password ?</span> */}
        <Button onClick={handleLogin} margin='10px' styled='primary'>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Connexion'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => loginStyles(props)}
`;

const mapStateToProps = ({ login }: RootState) => {
  return {
    username: login.username,
    password: login.password,
    error: login.error,
    loading: login.loading,
  };
};

export const connector = connect(mapStateToProps, {
  loginPropsChanged,
  onLogin,
});

export default connector(Login);
