import { css } from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const resetPasswordStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      height: 340px;
      padding: 30px;
    }
    .div-inputs {
      margin: 10px;
    }
    img {
      margin-bottom: 30px;
    }

    .text-error {
      font-size: 14px;
      margin: 4px;
      color: #ed0723;
    }
    .text-error-validation {
      font-size: 24px;
      color: #ed0723;
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },

    circularProgress: {
      color: '#ff5078',
    },
  })
);
