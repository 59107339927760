import {
  SETTINGS_PROPS_CHANGED,
  SETTINGS_VALIDATION_PROPS,
  SETTINGS_INITIAL_STATE,
  SETTINGS_GET_WALLET_SETTINGS,
} from '../actions/types';
import {
  SettingsState,
  SettingsActionTypes,
} from '../modules/Settings/settings.types';

const INITIAL_STATE: SettingsState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  oldPasswordError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  loading: false,
  showBarcode: true,
  communicationChannels: '',
};

export default (
  state = INITIAL_STATE,
  action: SettingsActionTypes
): SettingsState => {
  switch (action.type) {
    case SETTINGS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case SETTINGS_VALIDATION_PROPS:
      return { ...state, ...action.newState, loading: false };
    case SETTINGS_GET_WALLET_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
