import styled from 'styled-components';

export const InputFileWrapper = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 120px; */
  /* @media (max-width: 850px) {
    max-width: 230px;
    width: 230px;
  } */
  /* flex: 1; */
  .input-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    box-sizing: border-box;
    font-size: 12px;
    margin: 15px 0 10px 0;
    padding: 9px 30px 9px 30px;
    border-radius: 12px;
    width: fit-content;
    text-align: center;
    color: #fff;
    background: #ff5078;
    transition: all 0.3 ease;
  }
  .input-label:hover {
    cursor: pointer;
    background: #ff6878;
  }
  .logo-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgb(26, 45, 53);
    margin-top: 15px;
    text-align: center;
    width: 167px;
  }
  .img-content {
    margin-top: 8px;
    height: ${(props) => (props.height ? props.height : 120)}px;
    width: ${(props) => (props.width ? props.width : 280)}px;
    box-sizing: border-box;
    border-width: 0px;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(
      140deg,
      #4814f40d -10%,
      #f75aa00d 50%,
      #feae6e0d 110%
    );
    /* @media (max-width: 850px) {
      width: 230px;
    } */
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .text-error {
    /* align-self: flex-start; */
    font-size: 14px;
    color: #ed0723;
    margin-bottom: 8px;
    margin: 5px;
  }
`;
