import {
  getWalletByIdentifierQr,
  addCustumerToWallet,
  addCustomerDevice,
} from '../apis/addCustomerApi';
import {
  ADD_CUSTOMER_PROPS_CHANGED,
  ADD_CUSTOMER_GET_WALLET_DETAIL,
  ADD_CUSTOMER_FORM_CUSTOMER,
  ADD_CUSTOMER_GET_WALLET_DETAIL_FAILED,
  ADD_CUSTOMER_INITIAL_STATE,
  AppThunk,
  WALLET_GET_WALLET_SUBSCRIPTION_SETTINGS,
  SETTINGS_GET_WALLET_SETTINGS,
  SNACKBAR_ERROR,
} from './types';
import { validateFieldsAddCustomer } from '../modules/AddCustomer/addCustomer.utils';

import {
  AddCustomerState,
  AddCustomerPropsChangedAction,
  AddCustomerInitialStateAction,
} from '../modules/AddCustomer/addCustomer.types';
import { GetWalletApiResponse, GetWalletApiError } from '../apis/ApiTypes';
import { getSettings } from '../apis/settingsApi';

export const addCustomerPropsChanged = (
  prop: string,
  value: any
): AddCustomerPropsChangedAction => {
  return { type: ADD_CUSTOMER_PROPS_CHANGED, prop, value };
};

export const getWalletDetail = (identifierQr: string): AppThunk => (
  dispatch
) => {
  getWalletByIdentifierQr(
    identifierQr,
    (error: GetWalletApiError, res: GetWalletApiResponse) => {
      if (error && error.response) {
        let message = '';
        if (error.response.status === 401) {
          message = "Vous n'êtes pas autorisé.";
        } else if (error.response.status === 404) {
          message = 'Wallet est introuvable ';
        }
        dispatch({
          type: ADD_CUSTOMER_GET_WALLET_DETAIL_FAILED,
          payload: message,
        });
      } else {
        getSettings(res.response.data[0]._id).then((res: any) => {
          dispatch({
            type: SETTINGS_GET_WALLET_SETTINGS,
            payload: res.response.data,
          });
        });

        dispatch({
          type: ADD_CUSTOMER_GET_WALLET_DETAIL,
          payload: res.response.data[0],
        });
      }
    }
  );
};

export const addCustomer = (
  walletId: string,
  props: AddCustomerState,
  callback: (
    message: string,
    links: [string],
    subscriptionMessage: string,
    customerId: string
  ) => void,
  deviceId?: string
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsAddCustomer(props);
  dispatch({ type: ADD_CUSTOMER_FORM_CUSTOMER, newProps });
  if (valid) {
    dispatch({
      type: ADD_CUSTOMER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    addCustumerToWallet(walletId, newProps)
      .then((res) => {
        dispatch({
          type: ADD_CUSTOMER_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });

        if (res.data.status === 'success') {
          if (deviceId) {
            let customerId = res.data.response.data.newCustomerResult._id;
            addCustomerDevice(walletId, deviceId, customerId, null);
          }
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });

          callback(
            res.data.response.data.successMessage,
            res.data.response.data.links,
            res.data.response.data.subscriptionMessage,
            res.data.response.data.newCustomerResult._id
          );
        } else if (res.status === 400) {
          if (
            res.data.response?.data.smsStatus == false &&
            res.data.response?.data.emailSentStatus == false
          ) {
            dispatch({
              type: ADD_CUSTOMER_PROPS_CHANGED,
              prop: 'emailBarcodeError',
              value: "Problème d'envoi de SMS et de mail",
            });
            return;
          }

          if (
            res.data.response?.data.smsStatus == false &&
            res.data.response?.data.emailSentStatus == null
          ) {
            dispatch({
              type: ADD_CUSTOMER_PROPS_CHANGED,
              prop: 'emailBarcodeError',
              value: "Problème d'envoi de SMS",
            });
            return;
          }
          if (
            res.data.response?.data.smsStatus == null &&
            res.data.response?.data.emailSentStatus == false
          ) {
            dispatch({
              type: ADD_CUSTOMER_PROPS_CHANGED,
              prop: 'emailBarcodeError',
              value: "Problème d'envoi de mail",
            });
            return;
          }
        } else {
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'Erreur est survenue',
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ADD_CUSTOMER_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: err.message,
        });
      });
  }
};

export const addCustomerInitialState = (): AddCustomerInitialStateAction => {
  return { type: ADD_CUSTOMER_INITIAL_STATE };
};
