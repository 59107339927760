import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

//Styles
import { useStyles } from './statsCard.styles';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';
import CountUp from 'react-countup';
import { StatsDetailsFilterSMS } from '../../actions';
import { connect } from 'react-redux';
import { CardActionArea } from '@material-ui/core';

const StatsCard: React.FC<any> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { title, count, walletId, startDate, endDate, allTime, type } = props;
  const [is_loading, setIsLoading] = React.useState<any>(null);

  const HandleClickExpand = () => {
    if (type !== 'client')
      props.StatsDetailsFilterSMS(walletId, allTime, startDate, endDate, type);
  };

  return (
    <div>
      <Card
        className={`${title === 'Le total' ? classes.totalCard : ''} , ${
          classes.root
        }`}
        onClick={HandleClickExpand}
      >
        {' '}
        <CardActionArea>
          <CardContent>
            <Typography color='textSecondary' align='center' gutterBottom>
              {title}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              align='center'
              className='MuiTypography-h2'
            >
              <CountUp end={count} duration={1} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export const connector = connect(null, {
  StatsDetailsFilterSMS,
});

export default connector(StatsCard);
