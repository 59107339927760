import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//LOGIN
export const LOGIN_FORM_PROPS_CHANGED = 'login_form_props_changed';
export const LOGINING_SUCCESS = 'logining_success';
export const LOGINING_FAILED = 'logining_failed';
export const LOGINING_INITIAL_STATE = 'logining_initial_state';

//WALLET FORM
export const WALLET_FORM_PROPS_CHANGED = 'wallet_form_props_changed';
export const WALLET_FORM_WALLET = 'wallet_form_wallet';
export const WALLETS_ADD_WALLET_TO_LIST = 'wallets_add_wallet_to_list';
export const WALLET_FORM_INITIAL_STATE = 'wallet_form_initial_state';
export const WALLETS_UPDATE_WALLET_TO_LIST = 'wallets_update_wallet_to_list';

//WALLETS
export const WALLETS_GET_LIST_WALLETS = 'wallets_get_list_wallets';
export const WALLETS_SET_WALLET = 'wallets_set_wallet';
export const WALLETS_DELETE_WALLET = 'wallets_delete_wallet';
export const WALLETS_DELETE_WALLET_LOADING = 'wallets_delete_wallet_loading';
export const WALLET_GET_WALLET_SUBSCRIPTION_SETTINGS =
  'wallet_get_wallet_subscripition_settings';
export const WALLETS_GET_SMS_SETTINGS = 'wallets_get_sms_settings';
//WALLET DETAIL
export const WALLET_DETAIL_GET_WALLET_DETAIL =
  'wallet_detail_get_wallet_detail';
export const WALLET_DETAIL_INITIAL_STATE = 'wallet_detail_initial_state';
export const WALLET_DETAIL_GET_WALLET_DETAIL_FAILED =
  'wallet_detail_get_wallet_detail_failed';

export const WALLET_DETAIL_GET_CUSTOMER_HISTORY =
  'wallet_detail_get_customer_history';
export const WALLET_DETAIL_LOADING_HISTORY = 'wallet_detail_loading_history';

// DEVICE NOTIFICATION
export const NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED =
  'notification_subscribtion_props_changed';

//SETTINGS
export const SETTINGS_PROPS_CHANGED = 'settings_props_changed';
export const SETTINGS_VALIDATION_PROPS = 'settings_validation_props';
export const SETTINGS_INITIAL_STATE = 'settings_initial_state';
export const SETTINGS_GET_WALLET_SETTINGS = 'settings_get_wallet_settings';

//ADD CUSTOMER
export const ADD_CUSTOMER_PROPS_CHANGED = 'add_customer_props_changed';
export const ADD_CUSTOMER_GET_WALLET_DETAIL = 'add_customer_get_wallet_detail';
export const ADD_CUSTOMER_FORM_CUSTOMER = 'add_customer_form_customer';
export const ADD_CUSTOMER_GET_WALLET_DETAIL_FAILED =
  'add_customer_get_wallet_detail_failed';
export const ADD_CUSTOMER_INITIAL_STATE = 'add_customer_initial_state';

//FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

//RESET PASSWORD
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_VALIDATION_PROPS =
  'reset_password_validation_props';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';

//SNACKBAR
export const SNACKBAR_SUCCESS = 'snackbar_success';
export const SNACKBAR_ERROR = 'snackbar_error';
export const SNACKBAR_INFO = 'snackbar_info';
export const SNACKBAR_CLEAR = 'snackbar_clear';

//STATISTICS
export const STATISTICS_INITIAL_STATE = 'statistics_initial_state';
export const STATISTICS_GET_SMS_COUNT = 'statistics_get_sms_count';
export const STATISTICS_GET_CLIENTS_COUNT = 'statistics_get_clients_count';
export const STATISTICS_GET_EMAIL_COUNT = 'statistics_get_email_count';

export const STATISTICS_PROPS_CHANGED = 'statistics_props_changed';
export const STATISTICS_FILTER_SMS_STATS = 'statistics_filter_sms_stats';
export const STATISTICS_FORM_PROPS = 'statistics_form_props';

export const STATISTICS_GET_STATS_DETAIL = 'statistics_get_stats_detail';
export const STATISTICS_DETAILS_PROPS_CHANGED =
  'statistics_details_props_changed';
