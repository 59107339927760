import React, { useState } from 'react';
import { osName } from 'react-device-detect';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from '../../apis/config';

import SubscriptionPageApple from '../../components/subscriptionPageApple';
import LoadingIndicator from '../../components/LoadingIndicator';
import NotificationsRequest from '../NotificationsRequest';

const CouponInscription: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState<any>();
  const [links, setLinks] = useState<any>('');

  React.useEffect(() => {
    const fetchData = async () => {
      let requestUrl = `${URL}/customers/inscriptions`;
      const key: any = API_KEY;
      const value: string | undefined = API_KEY_VALUE;
      try {
        const result = await axios.post(
          requestUrl,
          {
            token: `${props.match.params.token}`,
          },
          {
            headers: {
              [key]: value,
            },
          }
        );
        setData(result.data);
        if (osName === 'iOS' || osName === 'Mac OS') {
          setLinks(result.data.response.data.data.links);
          forwardLink(result.data.response.data.data.links, osName);
        }
      } catch (error) {
        setIsError(true);
        console.log(error);
        setErrorMessage(error.response.data && error.response.data.message);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const forwardLink = (link: any, osName: string) => {
    if (osName !== 'iOS' && osName !== 'Mac OS') {
      const googlePayLink = link.googlePayLink.slice(33);
      window.location.href =
        'https://pay.google.com/gp/v/a/save/' + googlePayLink;
    } else {
      window.location.href = link.applePayLink.pkpassUrlLink;
    }
  };

  return (
    <Container>
      {isLoading && osName !== 'iOS' && osName !== 'Mac OS' && (
        <LoadingIndicator />
      )}
      {isError && <h2 className='errorMessage'>{errorMessage}</h2>}

      {data && osName !== 'iOS' && osName !== 'Mac OS' && (
        <NotificationsRequest
          data={data.response.data.data}
          forward={() => forwardLink(data.response.data.data.links, osName)}
        ></NotificationsRequest>
      )}

      {isLoading && (osName == 'iOS' || osName == 'Mac OS') && (
        <CircularProgress size={40} color='inherit' />
      )}
      {(osName == 'iOS' || osName == 'Mac OS') && (
        <SubscriptionPageApple forward={() => forwardLink(links, osName)} />
      )}
    </Container>
  );
};

const Container = styled('div')`
  iframe {
    height: 50px !important;
  }
  .errorMessage {
    color: red;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100vh;
  .MuiCircularProgress-root {
    margin-top: 43px;
  }
`;

export default CouponInscription;
