import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { getEmptyDataStyles, useStyles } from './emptyData.styles';

export type EmptyDataProps = {
  data: Array<any>;
  image?: string;
  loading?: boolean;
  message?: string;
  children?: React.ReactNode | any;
};

const EmptyData: React.FC<EmptyDataProps> = (props) => {
  const classes = useStyles(props);
  const { data, loading, image, message, children } = props;
  if (loading) {
    return (
      <Wrapper className={classes.root}>
        <CircularProgress className={classes.circularProgress} />
      </Wrapper>
    );
  } else if (data && data.length === 0 && !loading) {
    return (
      <Wrapper>
        <div className='img-container'>
          <img src={image} alt='empty-state' />
        </div>
        <p>{message}</p>
      </Wrapper>
    );
  }
  return children;
};

const Wrapper = styled('div')`
  ${(props) => getEmptyDataStyles(props)}
`;
export default EmptyData;
