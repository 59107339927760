import React from 'react';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { walletDetailStyles, useStyles } from './walletDetail.styles';
//Material

import CircularProgress from '@material-ui/core/CircularProgress';
//Components
import Table from '../../components/Table';
//Actions
import { getCustomersByWallet, getCustomerHistory } from '../../actions';
//Api
import { URL_IMAGE } from '../../apis/config';
import EmptyData from '../../components/EmptyData';

//Images
import emptyState from '../../assets/images/emptyState.svg';
import { WalletDetailProps } from './walletDetail.types';
import { RootState } from '../../reducers';
import TablePagination from '@material-ui/core/TablePagination';
import { useHistory } from 'react-router-dom';

const WalletDetail: React.FC<WalletDetailProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  let page = query.get('page') || 0;
  let size = query.get('size') || 10;
  React.useEffect(() => {
    props.getCustomersByWallet(props.match.params.walletId, page, size);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    query.delete('page');
    query.append('page', newPage.toString());
    history.push({ search: query.toString() });
    props.getCustomersByWallet(props.match.params.walletId, newPage, size);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    query.delete('size');

    query.append('size', event.target.value.toString());
    history.push({ search: query.toString() });
    props.getCustomersByWallet(
      props.match.params.walletId,
      page,
      event.target.value.toString()
    );
  };

  const handleClickHistory = (customerId: string) => {
    props.getCustomerHistory(customerId);
  };

  const { wallet, loading, error } = props;

  const renderBody = () => {
    if (loading) {
      return (
        <div className={classes.root}>
          <CircularProgress className={classes.circularProgress} />
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.root}>
          <span className='text-error'>{error}</span>
        </div>
      );
    } else if (wallet) {
      return (
        <Wrapper background={wallet.backgroundColor.hex}>
          <div className='div-header'>
            <div className='logo-container'>
              <img src={`${URL_IMAGE}/${wallet.logoPictureUrl}`} alt='logo' />
            </div>
            <span className='wallet-name'>{wallet.name}</span>
          </div>

          <div className='div-container'>
            <span className='table-title'>Liste des clients</span>

            <EmptyData
              data={wallet.customers}
              loading={loading}
              message='La liste des clients est vide'
              image={emptyState}
            >
              <Table
                data={wallet.customers}
                handleClickHistory={handleClickHistory}
              ></Table>
            </EmptyData>
          </div>
          {props.totalCustomersCount !== 0 && (
            <TablePagination
              count={props.totalCustomersCount}
              page={+page}
              onChangePage={handleChangePage}
              rowsPerPage={+size}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Wrapper>
      );
    }
  };

  return (
    <React.Fragment>
      {/* <Header history={props.history}></Header> */}
      {renderBody()}
    </React.Fragment>
  );
};

type WrapperProps = {
  background?: string;
};

const Wrapper = styled('div')<WrapperProps>`
  ${(props) => walletDetailStyles(props)}
`;

const mapStateToProps = ({ walletDetail }: RootState) => {
  return {
    totalCustomersCount: walletDetail.totalCustomersCount,
    wallet: walletDetail.wallet,
    loading: walletDetail.loading,
    error: walletDetail.error,
  };
};

export const connector = connect(mapStateToProps, {
  getCustomersByWallet,
  getCustomerHistory,
});

export default connector(WalletDetail);
