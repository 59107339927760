import { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const getEmptyDataStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-container {
      width: 180px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 500;
      color: #000;
      margin: 0;
    }
  `;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  circularProgress: {
    color: '#ff5078',
  },
}));
