import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import './App.css';

//COMPONENTS

// MODULES
import Login from './modules/Login';
import Statistics from './modules/Statistics';
import Settings from './modules/Settings';
import AddCustomer from './modules/AddCustomer';
import CustomerInscription from './modules/CustomerInscription';
import CustomerInscriptionInternal from './modules/CustomerInscriptionInternal';
import ResetPassword from './modules/ResetPassword';
import EventInscription from './modules/EventInscription';
import NotFound from './modules/NotFound';
import Terms from './modules/Terms';
import Shortlink from './modules/Shortlink';
import CouponInscription from './modules/CouponInscription';
//Utils
import { PrivateRoute } from './utils/Navigation';
import WalletsRouter from './routes/WalletsRouter';
import { removeFromCache } from './utils/cache';
import SnackBar from './modules/SnackBar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NotificationSubscription from './modules/NotificationSubscription';

export const theme = {
  primary: '#ff5078',
  secondary: '#4c15f1',
  bluePrimary: '#1A2D35',
  white: '#ffffff',
};

const App: React.FC = (props) => {
  //  removeFromCache('token');
  return (
    <Provider store={createStore(reducers, applyMiddleware(thunk))}>
      <MuiPickersUtilsProvider locale='fr' utils={MomentUtils}>
        <SnackBar />
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Redirect exact from='/' to='/login' />
              <Route path='/login' component={Login} exact />
              <Route
                path='/wallet/:identifierQr'
                component={AddCustomer}
                exact
              />
              {/* <Route path='/wallet/:token' component={AddCustomerBy} exact /> */}
              <Route
                path='/inscription'
                exact
                render={(props) => <CustomerInscription {...props} />}
              />
              <Route
                path='/inscription/intern/:token'
                exact
                render={(props) => <CustomerInscriptionInternal {...props} />}
              />
              <Route
                path='/inscription/event/:token'
                exact
                render={(props) => <EventInscription {...props} />}
              />
              <Route
                path='/inscription/coupon/:token'
                exact
                render={(props) => <CouponInscription {...props} />}
              />
              <Route
                path='/reset_password/:token'
                component={ResetPassword}
                exact
              />
              <Route path='/terms' component={Terms} exact />
              {/* <PrivateRoute path='/statistics' component={Statistics} /> */}
              <PrivateRoute path='/wallets' component={WalletsRouter} />
              <PrivateRoute path='/settings' component={Settings} />
              <Route path={`/statistics`} component={Statistics} />
              <Route
                path={`/notifications_settings`}
                component={NotificationSubscription}
              />
              <Route path='/:id' component={Shortlink} />
              <Route path='/*' component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
