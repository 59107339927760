import { isEmpty, isEmail, isNumber } from '../../utils/validations';

export const validateFieldsAddCustomer = (props: any) => {
  let newProps = {
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    phoneNumber: props.phoneNumber,
    barcode: props.barcode,
    firstNameError: props.firstNameError,
    lastNameError: props.lastNameError,
    emailError: props.emailError,
    phoneNumberError: props.phoneNumberError,
    barcodeError: props.barcodeError,
    emailBarcodeError: props.emailBarcodeError,
  };

  if (isEmpty(newProps.phoneNumber) && isEmpty(newProps.email)) {
    newProps.emailBarcodeError =
      'Email ou le numéro de téléphone sont nécessaires';
  } else {
    newProps.emailBarcodeError = '';
  }

  if (!isEmpty(newProps.phoneNumber)) {
    if (!isNumber(newProps.phoneNumber)) {
      newProps.phoneNumberError = 'Numéro de téléphone est invalide';
    } else if (newProps.phoneNumber.toString().length > 15) {
      newProps.phoneNumberError =
        'Le numéro de téléphone ne doit pas dépasser 15 chiffres';
    } else {
      newProps.phoneNumberError = '';
    }
  }

  if (!isEmpty(newProps.email)) {
    if (!isEmail(newProps.email)) {
      newProps.emailError = 'Email est invalide';
    } else {
      newProps.emailError = '';
    }
  }

  if (isEmpty(newProps.firstName)) {
    newProps.firstNameError = 'Nom est obligatoire';
  } else {
    newProps.firstNameError = '';
  }
  if (isEmpty(newProps.lastName)) {
    newProps.lastNameError = 'Prénom est obligatoire';
  } else {
    newProps.lastNameError = '';
  }

  if (newProps.barcode.length > 0 && newProps.barcode.length < 8) {
    newProps.barcodeError =
      'le code à barre doit comporter au moins 8 caractères';
  }

  let valid = false;
  if (
    newProps.firstNameError === '' &&
    newProps.lastNameError === '' &&
    newProps.emailError === '' &&
    newProps.barcodeError === '' &&
    newProps.phoneNumberError === '' &&
    newProps.emailBarcodeError == ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};
