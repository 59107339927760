import { css } from 'styled-components';

export const settingsStyles = (props: any) => {
  return css`
    display: flex;
    flex: 1;
    flex-direction: column;
    .password-section {
      display: flex;
      flex-direction: row;
      /* align-items: flex-end; */
      div {
        /* width: 260px; */
      }
      .icon {
        position: relative;
        top: 57px;
        left: -30px;
        img {
          height: 18px;
          width: auto;
          margin-right: -18px;
        }
      }
    }
    .settings-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .settings-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #404040;
        margin: 0;
      }
    }
    .settings-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .input-label-style {
      max-width: 100%;
      width: 100%;
    }
    .update-email-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 0px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      margin-bottom: 20px;
      width: 550px;
      .update-email-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;

        color: #404040;
      }
    }
    .update-pass-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 0px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      width: 550px;
      .update-pass {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
      .update-pass-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #404040;
        padding-bottom: 30px;
      }
    }
  `;
};
