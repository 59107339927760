import axios from 'axios';
import { SettingsApiResponse } from './ApiTypes';
import { URL, API_KEY, API_KEY_VALUE } from './config';

export const updatePassword = (userId: string | null, props: any) => {
  const requestURL = `${URL}/users/${userId}/update_password`;
  return axios
    .patch(requestURL, {
      passwordCurrent: props.oldPassword,
      password: props.newPassword,
      passwordConfirm: props.confirmPassword,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

export const getSettings = (walletID: string) => {
  const key: any = API_KEY;
  const value: string | undefined = API_KEY_VALUE;

  const requestURL = `${URL}/settings/${walletID}`;

  return axios
    .get(requestURL, {
      headers: {
        [key]: value,
      },
    })
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

// export const getSettings = (walletID: string) => {
//   const requestURL = `${URL}/settings/${walletID}`;

//   return axios
//     .get(requestURL)
//     .then(({ data }: { data: SettingsApiResponse }) => {
//       return data;
//     })
//     .catch((error) => {
//       throw new Error(error);
//     });
// };
