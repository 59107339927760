import {
  RESET_PASSWORD_PROPS_CHANGED,
  RESET_PASSWORD_VALIDATION_PROPS,
  RESET_PASSWORD_VERIFICATION_TOKEN_FAILED,
  RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS,
  RESET_PASSWORD_INITIAL_STATE,
} from '../actions/types';
import {
  ResetPasswordState,
  ResetPasswordActionTypes,
} from '../modules/ResetPassword/resetPassword.types';

const INITIAL_STATE: ResetPasswordState = {
  password: '',
  passwordConfirm: '',
  //errors
  passwordError: '',
  passwordConfirmError: '',
  errorMessage: '',
  error: '',
  //Loading
  loadingPage: true,
  loading: false,
};

export default (state = INITIAL_STATE, action: ResetPasswordActionTypes) => {
  switch (action.type) {
    case RESET_PASSWORD_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case RESET_PASSWORD_VALIDATION_PROPS:
      return { ...state, ...action.newState, loading: false };

    case RESET_PASSWORD_VERIFICATION_TOKEN_FAILED:
      return { ...state, error: action.payload, loadingPage: false };

    case RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS:
      return { ...state, error: '', loadingPage: false };

    case RESET_PASSWORD_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
