import {
  STATISTICS_FORM_PROPS,
  STATISTICS_GET_CLIENTS_COUNT,
  STATISTICS_GET_EMAIL_COUNT,
  STATISTICS_GET_SMS_COUNT,
  STATISTICS_INITIAL_STATE,
  STATISTICS_PROPS_CHANGED,
} from '../actions/types';
import {
  StatisticsState,
  StatsActionTypes,
} from '../modules/Statistics/stats.types';

const INITIAL_STATE: StatisticsState = {
  sentMessagesForCoupons: 0,
  sentMessagesForOffers: 0,
  sentMessagesForEvents: 0,
  sentMessagesForAccountCreation: 0,
  creditsQuantity: 0,
  totalSMS: 0,
  allTime: false,
  startDate: null,
  endDate: null,
  loading: false,
  //errors
  startDateError: '',
  endDateError: '',
  deletedAccountsiOS: 0,
  deletedAccountsAndroid: 0,
  actifAccountsiOS: 0,
  actifAccountsAndroid: 0,
  actifAccounts: 0,
  InactifAccounts: 0,
  extrnalSource: 0,
  internalSource: 0,
  crmSource: 0,
  totalCustomers: 0,
  loadingClientsStats: true,
  loadingClientsSMSStats: true,
  loadingEmailStats: true,
  couponEmails: 0,
  flightEmails: 0,
  passwordResetEmails: 0,
  eventsEmails: 0,
  walletUpdateEmails: 0,
  newAccountsEmails: 0,
  offersEmails: 0,
  totalEmails: 0,
  cinemaEmails: 0,
  hotelEmails: 0,
  transitEmails: 0,
};

export default (state = INITIAL_STATE, action: StatsActionTypes) => {
  switch (action.type) {
    case STATISTICS_GET_SMS_COUNT:
      let data = action.payload.data;

      return {
        ...state,
        ...data,
        sentMessagesForCoupons: data.coupons,
        sentMessagesForOffers: data.offers,
        sentMessagesForEvents: data.events,
        sentMessagesForAccountCreation: data.newAccounts,
        totalSMS: data.total,
        loadingClientsSMSStats: false,
      };
    case STATISTICS_GET_EMAIL_COUNT:
      const emailData = action.payload.data;
      return {
        ...state,
        newAccountsEmails: emailData.newAccounts,
        offersEmails: emailData.offers,
        eventsEmails: emailData.events,
        walletUpdateEmails: emailData.walletUpdate,
        passwordResetEmails: emailData.passwordReset,
        flightEmails: emailData.flight,
        couponEmails: emailData.coupons,
        totalEmails: emailData.total,
        ...emailData,
        loadingEmailStats: false,
      };
    case STATISTICS_GET_CLIENTS_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case STATISTICS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case STATISTICS_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };

    case STATISTICS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
