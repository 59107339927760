export const getImageErrorMessage = (file: File): string => {
  const fileType = file.type.replace('image/', '');
  return `${fileType.charAt(0)?.toUpperCase()}${fileType.slice(
    1
  )} n'est pas un type supporté`;
};

export const switchBarcode = (barcodeType: string, barcodes: any) => {
  let barcodeClass = 'rectangleCode';
  if (['AZTEC', 'QR'].includes(barcodeType)) {
    barcodeClass = 'squareCode';
  }
  return { barcode: barcodes[barcodeType], barcodeClass };
};
