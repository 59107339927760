import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      margin: '12px 0',
      background: '#FFFFFF',
      boxShadow: ' 0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
    },
    table: {
      minWidth: 650,
    },
    textEmpty: {
      fontSize: '16px',
      fontWeight: 500,
      margin: 0,
    },
    historyContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px',
    },
    circularProgressStyle: {
      color: '#ff5078',
    },

    imgContainer: {
      width: '120px',
    },
    imgStyle: {
      width: '100%',
      height: '100%',
    },
    delete_user: {
      padding: '5px',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
        cursor: 'pointer',
      },
    },
    modalDelete: {
      height: '160px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })
);
