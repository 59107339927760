import React, { useState } from 'react';
//Material
import Typography from '@material-ui/core/Typography';
import { notificationsStyles } from './NotificationSubscription';
//Style
import styled from 'styled-components';
import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from '../../apis/config';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import { connect } from 'react-redux';
import Button from '../../commons/Button';
import { RootState } from '../../reducers';
import {
  managePushNotifcations,
  notificationsPropsChanged,
} from '../../actions/deviceAction';
import CircularProgress from '@material-ui/core/CircularProgress';

const NotificationSubscription: React.FC<any> = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get('key') || '';
  const action = queryParams.get('action') || '';

  const { pushToken, loading } = props;
  const [deviceId, setDeviceId] = useState<String>('');

  React.useEffect(() => {
    props.notificationsPropsChanged('loading', true);

    const fetchData = async () => {
      let requestUrl = `${URL}/customers/devices/notifications`;
      try {
        const result = await axios.get(
          `${requestUrl}?key=${key}&action=${action}`
        );

        props.notificationsPropsChanged('pushToken', result.data.pushToken);

        if (action === 'subscribe') {
          const device = (await askForPermissioToReceiveNotifications()) as string;
          setDeviceId(device);
        }
        props.notificationsPropsChanged('loading', false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClickAction = () => {
    props.managePushNotifcations(key, action, deviceId);
  };

  const subscriptionPage = () => {
    return deviceId ? (
      <div className='bottom' style={{ marginTop: '20px' }}>
        <p className='subtitle'>
          Cliquez sur le bouton ci-dessous pour vous réabonner aux mises à jour
          de votre pass
        </p>
        <div className='buttons'>
          <Button
            styled='primary'
            onClick={handleClickAction}
            margin='10px'
            id='subscribe'
          >
            S'abonner
          </Button>
        </div>
      </div>
    ) : (
      <p className='subtitle' style={{ color: 'red', textAlign: 'center' }}>
        Activer les notifications pour continuer
      </p>
    );
  };

  const unsubscriptionPage = () => {
    return (
      <div className='bottom'>
        <h2 className='title'>Voulez-vous vraiment vous désinscrire?</h2>

        <p className='subtitle'>
          Si vous vous désabonnez, vous cesserez de recevoir nos mises à jour
          concernant votre pass
        </p>
        <div className='buttons'>
          <Button
            styled='primary'
            onClick={handleClickAction}
            margin='10px'
            id='unsubscribe'
          >
            Se désabonner
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <Typography variant='h5' component='h3'>
        <body>
          <div className='container'>
            {loading ? (
              <CircularProgress />
            ) : (
              <div className='inner-container'>
                {action === 'subscribe' && pushToken && (
                  <p className='subtitle' style={{ textAlign: 'center' }}>
                    Vous êtes abonné
                  </p>
                )}
                {action === 'unsubscribe' && !pushToken && (
                  <p className='subtitle' style={{ textAlign: 'center' }}>
                    Vous êtes désabonné des notifications
                  </p>
                )}
                {((action === 'subscribe' && !pushToken) ||
                  (action === 'unsubscribe' && pushToken)) &&
                  (action === 'subscribe'
                    ? subscriptionPage()
                    : unsubscriptionPage())}
              </div>
            )}
          </div>
        </body>
      </Typography>
    </Wrapper>
  );
};

type WrapperProps = {
  background?: string;
};
const Wrapper = styled('div')<WrapperProps>`
  ${(props) => notificationsStyles(props)}
`;

const mapStateToProps = ({ notificationSettings }: RootState) => {
  const { loading, pushToken } = notificationSettings;

  return {
    loading,
    pushToken,
  };
};

export const connector = connect(mapStateToProps, {
  managePushNotifcations,
  notificationsPropsChanged,
});

export default connector(NotificationSubscription);
