import {
  STATISTICS_DETAILS_PROPS_CHANGED,
  STATISTICS_GET_STATS_DETAIL,
} from '../actions/types';
import { StatisticsDetailState } from '../modules/Statistics/stats.types';

const INITIAL_STATE: StatisticsDetailState = {
  typeDetails: [],
  type: '',
  loadingDetails: false,
  status: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case STATISTICS_GET_STATS_DETAIL:
      return {
        ...state,
        type: action.payload.type,
        typeDetails: action.payload.data,
      };

    case STATISTICS_DETAILS_PROPS_CHANGED:
      return {
        ...state,
        [action.prop]: action.value,
      };

    default:
      return state;
  }
};
