import axios from 'axios';
import { URL, API_KEY, API_KEY_VALUE } from './config';

export const addCustumerDeviceId = (
  customerId: String,
  walletId: String,
  deviceId: String
) => {
  const requestURL = `${URL}/customers/devices`;
  const key: any = API_KEY;
  const value: string | undefined = API_KEY_VALUE;
  return axios
    .post(
      requestURL,
      {
        customerId,
        walletId,
        deviceId,
      },
      {
        headers: {
          // [API_KEY]: API_KEY_VALUE,
          [key]: value,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateDevicePushNotification = (
  key: string,
  action: string,
  deviceId: string
) => {
  let requestUrl = `${URL}/customers/devices/notifications`;

  return axios
    .put(requestUrl, { key, deviceId, action })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
