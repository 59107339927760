import { css } from 'styled-components';

export const loginStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      height: 340px;
      padding: 30px;
    }
    .div-inputs {
      display: flex;
      flex-direction: column;
      margin: 10px;
      width: 360px;
    }
    img {
      margin-bottom: 30px;
    }

    .login-text {
      font-family: 'Alata', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      color: #ff5078;
    }
    input {
      font-size: 16px;
    }
    .login-text-error {
      font-size: 14px;
      margin: 4px;
      color: #ed0723;
    }

    .forgot-password-text {
      align-self: flex-end;
      font-size: 14px;
      color: blue;
      text-decoration: underline;
      margin-top: 4px;
      cursor: pointer;
    }
  `;
};
