import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BACKEND_URL, API_KEY, API_KEY_VALUE } from '../../apis/config';
import NotFound from '../NotFound';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Shortlink: React.FC = () => {
  const slug: any = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    const fetchLink = async () => {
      let requestUrl = `${BACKEND_URL}/${slug.id}`;
      const key: any = API_KEY;
      const value: string | undefined = API_KEY_VALUE;
      try {
        const result = await axios.get(requestUrl, {
          headers: { [key]: value },
        });
        if (result.data.response !== null) {
          window.location.href = result.data.response;
        } else {
          throw Error;
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchLink();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Wrapper>
          <CircularProgress size={40} color='inherit' />{' '}
        </Wrapper>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

const Wrapper = styled('div')`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Shortlink;
