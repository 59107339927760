import React, { useState } from 'react';
import Bell from '../../assets/images/bell.gif';
import Alert from '../../assets/images/alert.png';

import { askForPermissioToReceiveNotifications } from '../../push-notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addCustomerDevice } from '../../apis/addCustomerApi';
import AlertDialogSlide from '../../components/SlideDialog/SlideDialog';
import { useIntl } from 'react-intl';
import { Wrapper } from './NotificationRequest.styles';

const NotificationsRequest: React.FC<any> = (props) => {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const intl = useIntl();
  React.useEffect(() => {
    if (Notification.permission === 'granted') {
      const getDeviceToken = async () => {
        let devices = (await askForPermissioToReceiveNotifications()) as string;

        addCustomerDevice(
          data.customer?.wallet || data.newCustomerResult.wallet,
          devices,
          data.customer?._id || data.newCustomerResult._id,
          data.kindId
        );
      };
      getDeviceToken();

      setButtonText('Redirecting...');
      props.forward();
      return;
    }

    if (Notification.permission === 'denied') {
      setButtonText(intl.formatMessage({ id: 'notification.page.blocked' }));
      setBlocked(true);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState(
    intl.formatMessage({ id: 'notification.page.buttonAllow' })
  );

  const requestNotificationPermission = async () => {
    if (isLoading) return;

    setIsLoading(true);
    let devices = (await askForPermissioToReceiveNotifications()) as string;
    setIsLoading(false);

    if (!devices) {
      setButtonText(intl.formatMessage({ id: 'notification.page.blocked' }));
      setBlocked(true);
      setOpen(true);
      return;
    } else {
      addCustomerDevice(
        data.customer?.wallet || data.newCustomerResult.wallet,
        devices,
        data.customer?._id || data.newCustomerResult._id,
        data.kindId
      );
    }
    setButtonText(intl.formatMessage({ id: 'notification.page.redirecting' }));
    props.forward();
  };
  const skipNotificationsPermissions = () => {
    props.forward();
  };

  return (
    <Wrapper>
      <AlertDialogSlide
        message={'d'}
        handleClose={() => setOpen(false)}
        open={open}
      ></AlertDialogSlide>
      <div className='body'>
        <div className='heading'>
          <img src={Bell} alt='Bell' className='bell' />
          <div className='title'>
            {intl.formatMessage({ id: 'notification.page.title' })}
          </div>
        </div>
        <div>
          <div className='description'>
            {data?.notificationMessage ||
              intl.formatMessage({ id: 'notification.page.description' })}
          </div>
          <div className='actions'>
            <button
              disabled={isLoading}
              className={blocked ? 'allow blocked' : 'allow'}
              onClick={requestNotificationPermission}
            >
              {blocked && <img src={Alert} alt='Alert' className='alert' />}
              {isLoading ? (
                <span>
                  <CircularProgress
                    size={20}
                    color='inherit'
                  ></CircularProgress>
                </span>
              ) : (
                buttonText
              )}
            </button>
            <button className='deny' onClick={skipNotificationsPermissions}>
              {intl.formatMessage({ id: 'notification.page.buttonSkip' })}
            </button>
          </div>
        </div>
      </div>
      <div className='bottom' onClick={() => setOpen(true)}>
        <a className='link'>
          {intl.formatMessage({ id: 'notification.page.showInsctructions' })}
        </a>
      </div>
    </Wrapper>
  );
};

export default NotificationsRequest;
