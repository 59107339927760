import {
  isEmail,
  isEmpty,
  isPhoneNumber,
  isUrl,
  isNumber,
} from '../../utils/validations';
import {
  FromWalletProps,
  WalletState,
  ColorProps,
  additionalContacts,
} from './walletForm.types';

//Create Wallet
export const validateFieldsCreateWallet = (props: FromWalletProps) => {
  let newProps: WalletState = {
    id: props.id,
    button: props.button,
    title: props.title,
    loading: props.loading,
    name: props.name,
    logo: props.logo,
    icon: props.icon,
    businessPhone: props.businessPhone,
    servicePhone: props.servicePhone,
    strip: props.strip,
    allowedPasses: props.allowedPasses,
    labelColor: {
      hex: props.labelColor.hex,
      rgb: props.labelColor.rgb,
    },
    backgroundColor: {
      hex: props.backgroundColor.hex,
      rgb: props.backgroundColor.rgb,
    },
    foregroundColor: {
      hex: props.foregroundColor.hex,
      rgb: props.foregroundColor.rgb,
    },
    address: props.address,
    additionalContacts: props.additionalContacts,
    facebook: props.facebook,
    instagram: props.instagram,
    linkedin: props.linkedin,
    website: props.website,
    locations: props.locations,
    openingHours: props.openingHours,
    descriptions: props.descriptions,
    username: props.username,
    email: props.email,
    password: props.password,
    confirmPassword: props.confirmPassword,
    login: props.login,
    communicationEmail: props.communicationEmail,
    usernameSMS: props.usernameSMS,
    passwordSMS: props.passwordSMS,
    emailingConfig: props.emailingConfig,
    expirationDate: props.expirationDate,
    period: props.period,
    periodLength: props.periodLength,
    expirationType: props.expirationType,
    barcodeType: props.barcodeType,
    // todo: remove this afterwards

    label: props.label,
    value: props.value,
    labelPersonnalizedText: props.labelPersonnalizedText,
    valuePersonnalizedText: props.valuePersonnalizedText,

    primaryFields: props.primaryFields,
    usernameSMSerror: '',
    passwordSMSerror: '',
    confirmPasswordError: '',
    additionalContactError: '',
    nameError: '',
    logoError: '',
    iconError: '',
    stripError: '',
    labelColorError: '',
    backgroundColorError: '',
    foregroundColorError: '',
    addressError: '',
    openingHoursError: '',
    facebookError: '',
    instagramError: '',
    linkedinError: '',
    websiteError: '',
    usernameError: '',
    emailError: '',
    passwordError: '',
    descriptionError: '',
    businessPhoneError: '',

    errorModal: false,
  };
  if (isEmpty(newProps.businessPhone)) {
    newProps.businessPhoneError = 'Téléphone commercial est obligatoire';
  }
  if (isEmpty(newProps.name)) {
    newProps.nameError = 'Nom de boutique est obligatoire';
  } else if (newProps.name.length > 18) {
    newProps.nameError =
      "La longueur maximale de l'ensigne ne doit pas dépasser 18 caractères";
  } else {
    newProps.nameError = '';
  }
  if (!newProps.logo) {
    newProps.logoError = 'Logo est obligatoire';
  } else {
    newProps.logoError = '';
  }
  if (!newProps.icon) {
    newProps.iconError = 'Icon est obligatoire';
  } else {
    newProps.iconError = '';
  }
  if (!newProps.strip) {
    newProps.stripError = 'Image de bande est obligatoire';
  } else {
    newProps.stripError = '';
  }

  if (!newProps.labelColor) {
    newProps.labelColorError = 'Couleur de text est obligatoire';
  } else {
    newProps.labelColorError = '';
  }

  if (!newProps.backgroundColor) {
    newProps.backgroundColorError = 'Couleur arrière plan est obligatoire';
  } else {
    newProps.backgroundColorError = '';
  }

  if (!newProps.foregroundColor) {
    newProps.foregroundColorError = 'Couleur de premier plan est obligatoire';
  } else {
    newProps.foregroundColorError = '';
  }

  if (isEmpty(newProps.address)) {
    newProps.addressError = ' Adresse est obligatoire';
  } else {
    newProps.addressError = '';
  }

  if (isEmpty(newProps.openingHours)) {
    newProps.openingHoursError = "Les horaires d'ouvertures est obligatoire";
  } else {
    newProps.openingHoursError = '';
  }

  if (!isEmpty(newProps.facebook) && !isUrl(newProps.facebook)) {
    newProps.facebookError = 'URL non valide';
  } else {
    newProps.facebookError = '';
  }

  if (!isEmpty(newProps.instagram) && !isUrl(newProps.instagram)) {
    newProps.instagramError = 'URL non valide';
  } else {
    newProps.instagramError = '';
  }

  if (!isEmpty(newProps.linkedin) && !isUrl(newProps.linkedin)) {
    newProps.linkedinError = 'URL non valide';
  } else {
    newProps.linkedinError = '';
  }

  if (!isEmpty(newProps.website) && !isUrl(newProps.website)) {
    newProps.websiteError = 'URL non valide';
  } else {
    newProps.websiteError = '';
  }

  if (isEmpty(newProps.username)) {
    newProps.usernameError = "Nom d'utilisateur est obligatoire";
  } else {
    newProps.usernameError = '';
  }

  if (isEmpty(newProps.email)) {
    newProps.emailError = 'Email est obligatoire';
  } else if (!isEmail(newProps.email)) {
    newProps.emailError = 'Email invalide';
  } else {
    newProps.emailError = '';
  }

  if (isEmpty(newProps.password)) {
    newProps.passwordError = 'Mot de passe est obligatoire';
  } else {
    if (newProps.password.length < 4) {
      newProps.passwordError =
        'Mots de passe est plus courte que la longueur minimale autorisée de 4.';
    } else if (newProps.password !== newProps.confirmPassword) {
      newProps.passwordError =
        'Les mots de passe ne correspondent pas. Veuillez réessayer.';
      newProps.confirmPasswordError =
        'Les mots de passe ne correspondent pas. Veuillez réessayer.';
    } else {
      newProps.passwordError = '';
    }
  }
  if (newProps.descriptions.length > 0) {
    if (
      newProps.descriptions[0].title === '' ||
      newProps.descriptions[0].value === ''
    ) {
      newProps.descriptionError =
        'La description est obligatoire, au moins une description doit être ajoutée.';
    } else {
      newProps.descriptionError = '';
    }
    newProps.descriptions.map((elm: any, index: any) => {
      if (index > 0) {
        if (elm.title === '' || elm.value === '') {
          let indexCopie = index + 1;
          newProps.descriptionError =
            'La description ' +
            indexCopie +
            ' contient des champs manquants. remplissez-les ou supprimer la description.';
        } else {
          newProps.descriptionError = '';
        }
      }
    });
  } else {
    newProps.descriptionError =
      'La description est obligatoire, au moins une description doit être ajoutée.';
  }
  if (newProps.additionalContacts) {
    for (let i = 0; i <= newProps.additionalContacts.length - 1; i += 1) {
      // console.log(newProps.additionalContacts[i], i);
      // if (isEmpty(newProps.additionalContacts[i].title)) {
      //   newProps.additionalContactError = `Dans contact ${
      //     i + 1
      //   } le titre est obligatoire`;
      //   break;
      // } else {
      //   newProps.additionalContactError = '';
      // }
      // if (isEmpty(newProps.additionalContacts[i].value)) {
      //   newProps.additionalContactError = `Dans contact ${
      //     i + 1
      //   } le valeur est obligatoire`;
      //   break;
      // } else {
      //   newProps.additionalContactError = '';
      // }
      if (
        newProps.additionalContacts[i].kind === 'URL' &&
        newProps.additionalContacts[i].value !== '' &&
        !isUrl(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact ${
          i + 1
        } URL non valide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (
        newProps.additionalContacts[i].kind === 'EMAIL' &&
        newProps.additionalContacts[i].value !== '' &&
        !isEmail(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact ${
          i + 1
        } Email non valide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (
        newProps.additionalContacts[i].kind === 'PHONE' &&
        newProps.additionalContacts[i].value !== '' &&
        !isNumber(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact ${
          i + 1
        } Téléphone non valide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
    }
  }
  const walletInfoSectionHasErrors = [
    newProps.nameError,
    newProps.businessPhoneError,
    newProps.instagramError,
    newProps.linkedinError,
    newProps.websiteError,
    newProps.addressError,
    newProps.openingHoursError,
    newProps.facebookError,
  ].some((element) => element != '');
  const descriptionSectionHasErrors = newProps.descriptionError !== '';
  const contactSectionHasErrors = newProps.additionalContactError !== '';
  const styleSectionHasErrors = [
    newProps.logoError,
    newProps.iconError,
    newProps.stripError,
    newProps.labelColorError,
    newProps.backgroundColorError,
    newProps.foregroundColorError,
  ].some((element) => element != '');

  const accountSectionHasErrors = [
    newProps.usernameError,
    newProps.emailError,
    newProps.passwordError,
  ].some((element) => element != '');

  const smsErrors = [newProps.usernameSMSerror, newProps.passwordSMSerror].some(
    (element) => element != ''
  );

  let valid = false;
  if (
    newProps.nameError === '' &&
    newProps.logoError === '' &&
    newProps.iconError === '' &&
    newProps.stripError === '' &&
    newProps.labelColorError === '' &&
    newProps.backgroundColorError === '' &&
    newProps.foregroundColorError === '' &&
    newProps.addressError === '' &&
    newProps.openingHoursError === '' &&
    newProps.facebookError === '' &&
    newProps.instagramError === '' &&
    newProps.linkedinError === '' &&
    newProps.websiteError === '' &&
    newProps.descriptionError === '' &&
    newProps.businessPhoneError === '' &&
    newProps.additionalContactError === '' &&
    newProps.usernameSMSerror === '' &&
    newProps.passwordSMSerror === ''
  ) {
    valid = true;
  } else {
    newProps.errorModal = true;
  }

  return {
    newProps: {
      ...newProps,
      walletInfoSectionHasErrors,
      styleSectionHasErrors,
      accountSectionHasErrors,
      descriptionSectionHasErrors,
      contactSectionHasErrors,
      smsErrors,
    },
    valid,
  };
};

export const newWalletObject = (wallet: WalletState) => {
  let formData = new FormData();
  let socialMedia = {
    facebook: wallet.facebook,
    instagram: wallet.instagram,
    linkedin: wallet.linkedin,
  };
  let additionalContactValue = wallet.additionalContacts;
  if (wallet.additionalContacts.length === 1) {
    if (
      wallet.additionalContacts[0].title === '' &&
      wallet.additionalContacts[0].value === ''
    ) {
      additionalContactValue = [];
    }
  }
  if (wallet.logo && wallet.icon && wallet.strip) {
    formData.append('name', wallet.name);
    formData.append('logo', wallet.logo);
    formData.append('icon', wallet.icon);
    formData.append('strip', wallet.strip);
    formData.append('businessPhone', wallet.businessPhone);
    formData.append('servicePhone', wallet.servicePhone);
    formData.append('locations', JSON.stringify(wallet.locations));
    formData.append('labelColor', objectColorJSON(wallet.labelColor));
    formData.append('backgroundColor', objectColorJSON(wallet.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(wallet.foregroundColor));
    formData.append(
      'additionalContacts',
      JSON.stringify(additionalContactValue)
    );
    formData.append('socialMedia', JSON.stringify(socialMedia));
    formData.append('website', wallet.website);
    formData.append('address', wallet.address);
    formData.append('openingHours', wallet.openingHours);
    formData.append('descriptions', JSON.stringify(wallet.descriptions));
    formData.append('username', wallet.username);
    formData.append('email', wallet.email);
    formData.append('passwordSMS', wallet.passwordSMS);
    formData.append('usernameSMS', wallet.usernameSMS);
    formData.append('allowedPasses', JSON.stringify(wallet.allowedPasses));
    formData.append('password', wallet.password);
    formData.append('barcodeType', wallet.barcodeType);

    if (wallet?.expirationDate) {
      formData.append(
        'expirationDate',
        new Date(wallet.expirationDate).toISOString()
      );
    }
    formData.append(
      'expirationAfterPeriod',
      wallet.periodLength + ',' + wallet.period
    );

    formData.append('expirationType', wallet.expirationType || 'NO_EXPIRATION');

    const iosHeaderFields = {
      label: wallet.label,
      value: wallet.value,
      labelPersonnalizedText: wallet.labelPersonnalizedText,
      valuePersonnalizedText: wallet.valuePersonnalizedText,
    };
    formData.append('iosHeaderFields', JSON.stringify(iosHeaderFields));
    // todo: remove this
    const hiddenFields = [];
    if (wallet.primaryFields.firstField === 'DISABLED') {
      hiddenFields.push('CREATION_DATE');
    }
    if (wallet.primaryFields.secondField === 'DISABLED') {
      hiddenFields.push('REWARDS');
    }
    formData.append('hiddenFields', JSON.stringify(hiddenFields));

    return formData;
  }

  return null;
};

//Update Wallet
export const validateFieldsUpdateWallet = (props: any) => {
  let newProps = {
    id: props.id,
    button: props.button,
    title: props.title,
    loading: props.loading,
    name: props.name,
    logo: props.logo,
    icon: props.icon,
    allowedPasses: props.allowedPasses,
    emailingConfig: props.emailingConfig,
    businessPhone: props.businessPhone,
    servicePhone: props.servicePhone,
    strip: props.strip,
    login: props.login,
    communicationEmail: props.communicationEmail,
    additionalContacts: props.additionalContacts,
    locations: props.locations,
    labelColor: {
      hex: props.labelColor.hex,
      rgb: props.labelColor.rgb,
    },
    backgroundColor: {
      hex: props.backgroundColor.hex,
      rgb: props.backgroundColor.rgb,
    },
    foregroundColor: {
      hex: props.foregroundColor.hex,
      rgb: props.foregroundColor.rgb,
    },
    label: props.label,
    value: props.value,
    labelPersonnalizedText: props.labelPersonnalizedText,
    valuePersonnalizedText: props.valuePersonnalizedText,
    primaryFields: props.primaryFields,
    address: props.address,
    facebook: props.facebook,
    instagram: props.instagram,
    linkedin: props.linkedin,
    website: props.website,
    openingHours: props.openingHours,
    descriptions: props.descriptions,
    usernameSMS: props.usernameSMS,
    passwordSMS: props.passwordSMS,
    expirationDate: props.expirationDate,
    period: props.period,
    periodLength: props.periodLength,
    distributionUpdateStrategy: props.distributionUpdateStrategy,
    expirationType: props.expirationType,
    barcodeType: props.barcodeType,
    usernameSMSerror: '',
    passwordSMSerror: '',
    additionalContactError: '',
    nameError: '',
    logoError: '',
    iconError: '',
    stripError: '',
    labelColorError: '',
    backgroundColorError: '',
    foregroundColorError: '',
    addressError: '',
    openingHoursError: '',
    facebookError: '',
    instagramError: '',
    linkedinError: '',
    websiteError: '',
    usernameError: '',
    emailError: '',

    passwordError: '',
    descriptionError: '',
    businessPhoneError: '',
    errorModal: false,
  };

  if (isEmpty(newProps.businessPhone)) {
    newProps.businessPhoneError = 'Téléphone commercial est obligatoire';
  }

  if (newProps.descriptions.length > 0) {
    if (
      newProps.descriptions[0].title === '' ||
      newProps.descriptions[0].value === ''
    ) {
      newProps.descriptionError =
        'La description est obligatoire, au moins une description doit être ajoutée.';
    } else {
      newProps.descriptionError = '';
    }
    newProps.descriptions.map((elm: any, index: any) => {
      if (index > 0) {
        if (elm.title === '' || elm.value === '') {
          let indexCopie = index + 1;
          newProps.descriptionError =
            'La description ' +
            indexCopie +
            ' contient des champs manquants. remplissez-les ou supprimer la description.';
        } else {
          newProps.descriptionError = '';
        }
      }
    });
  } else {
    newProps.descriptionError =
      'La description est obligatoire, au moins une description doit être ajoutée.';
  }
  if (isEmpty(newProps.name)) {
    newProps.nameError = 'Nom de boutique est obligatoire';
  } else if (newProps.name.length > 18) {
    newProps.nameError =
      "La longueur maximale de l'ensigne ne doit pas dépasser 18 caractères";
  } else {
    newProps.nameError = '';
  }
  if (!newProps.logo) {
    newProps.logoError = 'Logo est obligatoire';
  } else {
    newProps.logoError = '';
  }
  if (!newProps.icon) {
    newProps.iconError = 'Icon est obligatoire';
  } else {
    newProps.iconError = '';
  }
  if (!newProps.strip) {
    newProps.stripError = 'Image de bande est obligatoire';
  } else {
    newProps.stripError = '';
  }
  if (!newProps.labelColor) {
    newProps.labelColorError = 'Couleur de text est obligatoire';
  } else {
    newProps.labelColorError = '';
  }

  if (!newProps.backgroundColor) {
    newProps.backgroundColorError = 'Couleur arrière plan est obligatoire';
  } else {
    newProps.backgroundColorError = '';
  }

  if (!newProps.foregroundColor) {
    newProps.foregroundColorError = 'Couleur de premier plan est obligatoire';
  } else {
    newProps.foregroundColorError = '';
  }

  if (isEmpty(newProps.address)) {
    newProps.addressError = ' Adresse est obligatoire';
  } else {
    newProps.addressError = '';
  }

  if (isEmpty(newProps.openingHours)) {
    newProps.openingHoursError = "Les horaires d'ouvertures est obligatoire";
  } else {
    newProps.openingHoursError = '';
  }

  if (!isEmpty(newProps.facebook) && !isUrl(newProps.facebook)) {
    newProps.facebookError = 'URL non valide';
  } else {
    newProps.facebookError = '';
  }

  if (!isEmpty(newProps.instagram) && !isUrl(newProps.instagram)) {
    newProps.instagramError = 'URL non valide';
  } else {
    newProps.instagramError = '';
  }

  if (!isEmpty(newProps.linkedin) && !isUrl(newProps.linkedin)) {
    newProps.linkedinError = 'URL non valide';
  } else {
    newProps.linkedinError = '';
  }

  if (!isEmpty(newProps.website) && !isUrl(newProps.website)) {
    newProps.websiteError = 'URL non valide';
  } else {
    newProps.websiteError = '';
  }

  if (newProps.additionalContacts) {
    for (let i = 0; i <= newProps.additionalContacts.length - 1; i += 1) {
      if (
        !newProps.additionalContacts[i].title &&
        !newProps.additionalContacts[i].value
      )
        continue;

      if (isEmpty(newProps.additionalContacts[i].title)) {
        newProps.additionalContactError = `Dans contact supplémentaire ${
          i + 1
        } le titre est obligatoire`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (isEmpty(newProps.additionalContacts[i].value)) {
        newProps.additionalContactError = `Dans contact supplémentaire ${
          i + 1
        } la valeur est obligatoire`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (
        newProps.additionalContacts[i].kind === 'URL' &&
        !isUrl(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact supplémentaire ${
          i + 1
        } l'URL invalide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (
        newProps.additionalContacts[i].kind === 'EMAIL' &&
        !isEmail(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact supplémentaire ${
          i + 1
        } l'Email non valide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
      if (
        newProps.additionalContacts[i].kind === 'PHONE' &&
        !isPhoneNumber(newProps.additionalContacts[i].value)
      ) {
        newProps.additionalContactError = `Dans contact supplémentaire ${
          i + 1
        } le numéro de téléphone est invalide`;
        break;
      } else {
        newProps.additionalContactError = '';
      }
    }
  }

  const walletInfoSectionHasErrors = [
    newProps.nameError,
    newProps.businessPhoneError,
    newProps.instagramError,
    newProps.linkedinError,
    newProps.websiteError,
    newProps.addressError,
    newProps.openingHoursError,
    newProps.facebookError,
  ].some((element) => element != '');
  const descriptionSectionHasErrors = newProps.descriptionError !== '';
  const contactSectionHasErrors = newProps.additionalContactError !== '';

  const styleSectionHasErrors = [
    newProps.logoError,
    newProps.iconError,
    newProps.stripError,
    newProps.labelColorError,
    newProps.backgroundColorError,
    newProps.foregroundColorError,
  ].some((element) => element != '');

  const accountSectionHasErrors = [
    newProps.usernameError,
    newProps.emailError,
    newProps.passwordError,
  ].some((element) => element != '');

  const smsErrors = [newProps.usernameSMSerror, newProps.passwordSMSerror].some(
    (element) => element != ''
  );

  let valid = false;
  if (
    newProps.nameError === '' &&
    newProps.logoError === '' &&
    newProps.iconError === '' &&
    newProps.stripError === '' &&
    newProps.labelColorError === '' &&
    newProps.backgroundColorError === '' &&
    newProps.foregroundColorError === '' &&
    newProps.addressError === '' &&
    newProps.openingHoursError === '' &&
    newProps.facebookError === '' &&
    newProps.instagramError === '' &&
    newProps.linkedinError === '' &&
    newProps.websiteError === '' &&
    newProps.descriptionError === '' &&
    newProps.businessPhoneError === '' &&
    newProps.additionalContactError === '' &&
    newProps.usernameSMSerror === '' &&
    newProps.passwordSMSerror === ''
  ) {
    valid = true;
  } else {
    newProps.errorModal = true;
  }

  return {
    newProps: {
      ...newProps,
      walletInfoSectionHasErrors,
      styleSectionHasErrors,
      accountSectionHasErrors,
      descriptionSectionHasErrors,
      contactSectionHasErrors,
      smsErrors,
    },
    valid,
  };
};

export const updateWalletObject = (wallet: any) => {
  let formData = new FormData();
  let socialMedia = {
    facebook: wallet.facebook,
    instagram: wallet.instagram,
    linkedin: wallet.linkedin,
  };
  let additionalContactValue = wallet.additionalContacts;
  if (wallet.additionalContacts.length === 1) {
    if (
      wallet.additionalContacts[0].title === '' &&
      wallet.additionalContacts[0].value === ''
    ) {
      additionalContactValue = [];
    }
  }
  if (wallet.logo && wallet.icon && wallet.strip) {
    formData.append('name', wallet.name);
    formData.append('logo', wallet.logo);
    formData.append('icon', wallet.icon);
    formData.append('strip', wallet.strip);
    formData.append('labelColor', objectColorJSON(wallet.labelColor));
    formData.append('businessPhone', wallet.businessPhone);
    formData.append('locations', JSON.stringify(wallet.locations));
    formData.append('servicePhone', wallet.servicePhone);
    formData.append('backgroundColor', objectColorJSON(wallet.backgroundColor));
    formData.append('foregroundColor', objectColorJSON(wallet.foregroundColor));
    formData.append('socialMedia', JSON.stringify(socialMedia));
    formData.append(
      'additionalContacts',
      JSON.stringify(additionalContactValue)
    );
    formData.append('website', wallet.website);
    formData.append('address', wallet.address);
    formData.append('openingHours', wallet.openingHours);
    formData.append('descriptions', JSON.stringify(wallet.descriptions));
    formData.append('communicationEmail', wallet.communicationEmail);
    formData.append('login', wallet.login);
    formData.append('usernameSMS', wallet.usernameSMS);
    formData.append('passwordSMS', wallet.passwordSMS);
    formData.append('emailingConfig', wallet.emailingConfig);
    formData.append('allowedPasses', JSON.stringify(wallet.allowedPasses));
    formData.append('barcodeType', wallet.barcodeType);

    if (wallet?.expirationDate)
      formData.append(
        'expirationDate',
        new Date(wallet.expirationDate).toISOString()
      );
    formData.append(
      'expirationAfterPeriod',
      wallet.periodLength + ',' + wallet.period
    );
    formData.append(
      'distributionUpdateStrategy',
      wallet.distributionUpdateStrategy
    );
    formData.append('expirationType', wallet.expirationType);
    const iosHeaderFields = {
      label: wallet.label,
      value: wallet.value,
      labelPersonnalizedText: wallet.labelPersonnalizedText,
      valuePersonnalizedText: wallet.valuePersonnalizedText,
    };
    formData.append('iosHeaderFields', JSON.stringify(iosHeaderFields));
    // todo: remove this
    const hiddenFields = [];
    if (wallet.primaryFields.firstField === 'DISABLED') {
      hiddenFields.push('CREATION_DATE');
    }
    if (wallet.primaryFields.secondField === 'DISABLED') {
      hiddenFields.push('REWARDS');
    }
    formData.append('hiddenFields', JSON.stringify(hiddenFields));

    return formData;
  }

  return null;
};

export const objectColorJSON = (objectColor: ColorProps) => {
  let newObjectColor = {
    hex: objectColor.hex,
    rgb:
      typeof objectColor.rgb.r === 'number' &&
      typeof objectColor.rgb.g === 'number' &&
      typeof objectColor.rgb.b === 'number'
        ? `rgb(${objectColor.rgb.r},${objectColor.rgb.g},${objectColor.rgb.b})`
        : objectColor.rgb,
  };

  const newObjectColorJSON = JSON.stringify(newObjectColor);
  return newObjectColorJSON;
};
