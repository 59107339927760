import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BottomNavigation } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: 'fit-content',
      height: 'fit-content',
      // maxWidth: '650px',
      minWidth: '420px',
      minHeight: '110px',
      // maxHeight: '550px',
      overflowY: 'auto',
      borderRadius: '10px',
      // padding: '100px',
      paddingTop: '10px',
      // paddingBottom: '100px',
      paddingLeft: '50px',
      paddingRight: '50px',
      margin: 'auto',
      backgroundColor: '#FFFFFF',
    },
    boxContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'all',
    },
  })
);
