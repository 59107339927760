import { css } from 'styled-components';

export type WalletsStylesProps = {
  loading: string;
  dataLength: number;
};
export const walletsStyles = (props: WalletsStylesProps) => {
  let flex = 0;
  let justifyContent = 'flex-start';

  if (props.loading === 'true') {
    flex = 1;
    justifyContent = 'center';
  } else if (props.dataLength === 0 && props.loading === 'false') {
    flex = 1;
    justifyContent = 'center';
  }

  return css`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* min-height: calc(100vh - 60px); */

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .icon {
        position: absolute;
        top: 17px;
        right: 15px;
      }
      h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #404040;

        margin: 0;
      }
    }

    .wallet-list-style {
      display: flex;
      flex: ${flex};
      gap: 30px;
      align-items: flex-start;
      justify-content: ${justifyContent};
      flex-wrap: wrap;
    }
    @media (max-width: 750px) {
      .wallet-list-style {
        justify-content: center;
      }
    }

    /* .wallet-list-style div:last-child {
      margin-left: 0;
      align-self: flex-start;
    } */
  `;
};
