import styled from 'styled-components';

export const Wrapper = styled('div')<{}>`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  * {
    text-align: center;
    font-family: Poppins, arial, 'helvetica neue', helvetica, sans-serif;
    max-width: 427px;
  }

  .bell {
    display: block;
    height: auto;
    border: 0;
    max-width: 100%;
    max-height: 150px;
    margin: 0 auto;
  }

  .title {
    font-weight: 500;
    margin: 11px 51px 8px;
    font-size: 23px;
  }

  .heading {
    padding-bottom: 34px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0;
  }

  button {
    font-weight: 400;
    display: block;
    min-height: 50px;
    padding: 13px 24px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    background-position: top center;
    cursor: pointer;
  }

  .allow {
    width: 296px !important;
    color: white;
    border-radius: 10px;
    background-image: linear-gradient(
      90deg,
      rgba(73, 20, 244, 1),
      rgba(155, 186, 255, 1)
    );
    background-size: 400%;
    background-position: 0% 0%;
    max-width: 296px;
  }

  .description {
    margin: 0px 19px 12px;
  }

  .MuiCircularProgress-root {
    margin-top: 0px !important;
  }

  .link {
    color: #2acaea;
    text-decoration: none;
  }

  button:disabled,
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .blocked {
    background: linear-gradient(
      90deg,
      rgba(73, 20, 244, 0.1) 9.06%,
      rgba(155, 186, 255, 0.1) 100%
    );
    color: rgba(70, 70, 247, 1);
    font-weight: 400;
  }

  .alert {
    display: block;
    height: auto;
    max-width: 12%;
    max-height: 172px;
    margin: 0 auto;
  }

  .body {
    //height: 100%;
  }

  .bottom {
    width: 100%;
    cursor: pointer;
    opacity: 0.8;
    padding-bottom: 5vh;
  }
`;
