import {
  WALLET_DETAIL_GET_WALLET_DETAIL,
  WALLET_DETAIL_GET_WALLET_DETAIL_FAILED,
  WALLET_DETAIL_INITIAL_STATE,
  WALLET_DETAIL_GET_CUSTOMER_HISTORY,
  WALLET_DETAIL_LOADING_HISTORY,
} from '../actions/types';
import {
  WalletDetailActionTypes,
  WalletDetailState,
} from '../modules/WalletDetail/walletDetail.types';
import { WalletDetailApi, Customer } from '../apis/ApiTypes';

const INITIAL_STATE: WalletDetailState = {
  wallet: null,
  loading: true,
  totalCustomersCount: 0,
  error: '',
};

export default (state = INITIAL_STATE, action: WalletDetailActionTypes) => {
  switch (action.type) {
    case WALLET_DETAIL_GET_WALLET_DETAIL:
      return {
        ...state,
        wallet: action.payload,
        totalCustomersCount: action.totalCustomersCount,
        loading: false,
      };
    case WALLET_DETAIL_GET_WALLET_DETAIL_FAILED:
      return { ...state, error: action.payload, loading: false };

    case WALLET_DETAIL_GET_CUSTOMER_HISTORY: {
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      const customer = newWallet.customers.find(
        (item: Customer) => item._id === action.customerID
      );
      if (customer) {
        customer.loading = false;
        customer.history = action.payload;
      }

      return { ...state, wallet: newWallet };
    }

    case WALLET_DETAIL_LOADING_HISTORY: {
      let newWallet: WalletDetailApi = Object.assign({}, state.wallet);
      const customer = newWallet.customers.find(
        (item: Customer) => item._id === action.customerID
      );
      if (customer) {
        customer.loading = action.payload;
      }

      return { ...state, wallet: newWallet };
    }

    case WALLET_DETAIL_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
