import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './input.styles';
import { inputStylesNew } from './input.styles.new';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  width?: number;
  value?: string;
  error?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  theme?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Input = styled.input<InputProps>`
  ${(props) =>
    props.theme === 'blueTheme' ? inputStylesNew(props) : inputStyles(props)}
`;

export default Input;
