import React from 'react';
import { Wrapper } from './terms.styles';
import Logo from '../../assets/images/logo-bestWallet-white.png';

const Terms: React.FC = (props) => {
  return (
    <Wrapper>
      <div className='pageHeader'>
        <img src={Logo} alt='logo' />
        <h3>La politique de confidentialité</h3>
      </div>
      <div className='pageContent'>
        <div className='section'>
          <h4>Introduction</h4>
          <p>
            BestWallet est une solution de portefeuille virtuel natif (Wallet
            Mobile)
            <br /> Dans le cadre des « BestWallet » La protection de vos données
            personnelles est importante pour nous.
            <br /> Orgazone ainsi que BestWallet s’engagent, à traiter les
            données personnelles vous concernant conformément au RGPD N°
            2016/679 et à ne pas les divulguer à des tiers non autorisés.
            <br /> Nous respectons ainsi l’ensemble des dispositions applicables
            en matière de protection de la vie privée et des données à caractère
            personnel.
            <br />
            La présente politique de confidentialité décrit les données
            personnelles que nous recueillons, comment elles sont utilisées et
            vos droits à cet égard.
            <br /> Nous sommes susceptibles de modifier à tout moment cette
            politique de confidentialité.
            <br /> En cas d’évolution de l’application (autres données
            collectées, modification de finalité …) vous serez immédiatement
            informé afin que vous redonniez votre consentement.
          </p>
        </div>
        <div className='section'>
          <h4>Responsable du traitement des données</h4>
          <p>
            <b>BESTWALLET</b>
            <br />
            ORGAZONE SIREN : 432 583 482
            <br /> Responsable éditorial : Moni Aouad
            <br />
            Adresse : Orgazone – Pôle Européen d’Activités – 11 Place du
            Capitaine Dreyfus 68000 COLMAR
            <br /> Téléphone : +33 (0)3 89 23 02 46
            <br />
            Email : contact@orgazone.fr Site web : <br />
            <a href='www.bestWallet.fr'>www.bestwallet.fr</a>
          </p>
        </div>
        <div className='section'>
          <h4>Données que nous collectons</h4>
          <p>
            Dans le cadre de l’utilisation de nos services et de votre
            navigation sur notre application de Wallet Mobile, « Best Wallet » 
            collecte plusieurs catégories de données :
            <br />
            <ul>
              <li>
                Des informations que vous nous communiquez lorsque vous vous
                inscrivez (nom, prénom, email, etc…).
              </li>
              <li>
                Des données de localisation : nous vous demandons l’autorisation
                pour interroger notre application sur vos données précises de
                localisation mais aucune n’est enregistrées.
              </li>
              <li>Autres données nécessaires.</li>
            </ul>
          </p>
        </div>
        <div className='section'>
          <h4>Finalité</h4>
          <p>
            « BestWallet » procèdent au traitement de vos données à caractère
            personnel pour des finalités déterminées, explicites et légitimes.
            Ces données sont destinées :
            <ul>
              <li>
                Au bon fonctionnement de l’application (nom, prénom, mail...).
              </li>
              <li>
                A la commercialisation pour promouvoir la plateforme «
                BestWallet ».
              </li>
              <li>
                Envoie de notifications push avec des offres commerciales.
              </li>
              <li>Notification de géolocalisation.</li>
            </ul>
          </p>
        </div>
        <div className='section'>
          <h4>Destinataires ou catégories de destinataires</h4>
          <p>
            « BestWallet est destinataire des données à caractère personnel
            recueillies. Nous pourrions également être susceptibles de partager
            certaines informations pour des raisons juridiques ou en cas de
            litige. « BestWallet » ne revend pas vos données et ne transmettra
            jamais vos données personnelles à aucun tiers susceptible de les
            utiliser à ses propres fins et notamment à des fins commerciales
            et/ou de publicité directe, sans votre consentement.{' '}
          </p>
        </div>
        <div className='section'>
          <h4>Transfert des données HORS UE </h4>
          <p>
            Tous nos serveurs sur lesquels vos données sont conservées et ceux
            des prestataires utilisés pour échanger et stocker ces données sont
            localisées en Europe. Dans l’hypothèse où « BestWallet » auraient
            recours à des sous-traitants situés en dehors de l’Union européenne,
            tout transfert vers ces tiers est protégé par des cadres juridiques
            adéquats tels qu’un accord de transfert de données basé sur les
            clauses contractuelles types de la Commission Européenne.{' '}
          </p>
        </div>
        <div className='section'>
          <h4>Durée de conservation des données</h4>
          <p>
            Les données que BestWallet collecte sont traitées, enregistrées et
            stockées en conformité avec les dispositions légales en vigueur.
            Nous ne conservons vos données que le temps nécessaire à accomplir
            nos services ou dans les limites prescrites par la loi. A l’issue de
            ce délai, vos données seront supprimées.{' '}
          </p>
        </div>
        <div className='section'>
          {' '}
          <h4>Sécurité des données </h4>{' '}
          <p>
            « BestWallet » s’engagent à mettre en œuvre tous les moyens
            disponibles pour assurer la sécurité et la confidentialité de ces
            données.{' '}
          </p>
        </div>
        <div className='section'>
          <h4>Vos droits</h4>
          <p>
            Conformément à la réglementation sur les données personnelles, vous
            disposez d’un droit :
            <ul>
              <li>D’accès aux données à caractère personnel.</li>
              <li>A la rectification de ces données.</li>
              <li>A l’effacement de ces données.</li>
              <li>
                A limitation de traitement relatif à la personne concernée.
              </li>
              <li>De s’opposer au traitement.</li>
              <li>A la portabilité des données.</li>
              <li>
                Dans le cas de l’application de ne plus recevoir de notification
                push.
              </li>
            </ul>
          </p>
        </div>
        <div className='section'>
          <h4>Droit de faire une réclamation à la CNIL</h4>
          <p>
            L’autorité de contrôle compétente pour toute demande nous concernant
            est la Commission Nationale de l’Informatique et des Libertés
            (CNIL). Si vous souhaitez faire une réclamation à la CNIL, vous
            trouverez ci-dessous les coordonnées :
            <br />
            <br />
            <b>CNIL (Commission Nationale de l’Informatique et des Libertés)</b>
            <br />
            Adresse : 3 place de Fontenoy – TSA 80715- 75334 PARIS CEDEX 07
            <br />
            Tél. : 01.53.73.22.22 <br />
            (Du lundi au jeudi de 9h à 18h30/ le vendredi de 9h à 18h)
            <br /> Fax : 01.53.73.22.00 <br />
            Si vous souhaitez déposer une plainte auprès de la CNIL, vous pouvez
            renseigner le formulaire de dépôt de plainte en ligne disponible à
            l’adresse suivante :
            <br />
            <a href='https://www.cnil.fr/fr/plaintes'>
              https://www.cnil.fr/fr/plaintes
            </a>
            <br />
            Si vous avez une question sur vos droits informatique et libertés,
            vous pouvez consulter le site de la CNIL : <br />
            <a href='https://www.cnil.fr'>www.cnil.fr</a>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Terms;
