import axios from 'axios';
import { URL } from './config';
import { LoginApiResponse } from './ApiTypes';

export const authentication = (username: string, password: string) => {
  const requestURL = `${URL}/users/login`;
  return axios
    .post(requestURL, {
      username,
      password,
    })
    .then(({ data }: { data: LoginApiResponse }) => {
      return data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};
