import axios from 'axios';
import { URL } from './config';
import {
  WalletDetailApiResponse,
  CustomerHistoryApiResponse,
} from './ApiTypes';

export const getWalletListCustomers = (
  walletID: string,
  page: number | string,
  size: number | string,
  callback: any
) => {
  const requestURL = `${URL}/wallets/${walletID}/customers`;
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
      },
    })
    .then(({ data }: { data: WalletDetailApiResponse }) => {
      callback(null, data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export const getCustomerHistoryList = (customerID: string) => {
  const requestURL = `${URL}/customers/${customerID}/history`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: CustomerHistoryApiResponse }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
