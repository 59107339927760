import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { addCustomerStyles, useStyles } from './addCustomer.styles';
//Material
import { CircularProgress } from '@material-ui/core';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
//Api
import { URL_IMAGE } from '../../apis/config';
//Actions
import {
  addCustomerPropsChanged,
  getWalletDetail,
  addCustomer,
  addCustomerInitialState,
  getWalletSettings,
} from '../../actions';
import { AddCustomerFormProps } from './addCustomer.types';
import { RootState } from '../../reducers';
import 'firebase/firebase-messaging';

const AddCustomer: React.FC<AddCustomerFormProps> = (props) => {
  const classes = useStyles();
  const {
    wallet,
    firstName,
    lastName,
    email,
    phoneNumber,
    barcode,
    firstNameError,
    lastNameError,
    emailError,
    barcodeError,
    phoneNumberError,
    emailBarcodeError,
    loading,
    walletLoading,
    showBarcode,
    communicationChannels,
    error,
  } = props;
  //5efc695c4ad2e1dd7d66ea80
  const [openDialog, setOpenDialog] = React.useState(false);
  const [successMessage, setOpensuccessMessage] = React.useState('');
  const [subscriptionMessage, setSubscriptionMessage] = React.useState('');
  const [appLinks, setAppLinks] = React.useState(['']);
  const [deviceId, setDeviceId] = React.useState<string>('');

  // ###### NOTIFICATION - GET DEVICE ID #######

  React.useEffect(() => {
    props.getWalletDetail(props.match.params.identifierQr);
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addCustomerPropsChanged(event.target.name, event.target.value);
    props.addCustomerPropsChanged('firstNameError', '');
    props.addCustomerPropsChanged('lastNameError', '');
    props.addCustomerPropsChanged('emailError', '');
    props.addCustomerPropsChanged('phoneNumberError', '');
    props.addCustomerPropsChanged('barcodeError', '');
    props.addCustomerPropsChanged('emailBarcodeError', '');
  };

  function phoneInput() {
    return (
      <InputLabel
        label='Numéro de téléphone'
        name='phoneNumber'
        value={phoneNumber}
        error={phoneNumberError}
        placeholder='+33757130710'
        theme='blueTheme'
        onChange={(e) => handleEventChange(e)}
      />
    );
  }

  function emailInput() {
    return (
      <InputLabel
        label='Email'
        name='email'
        value={email}
        error={emailError}
        placeholder='Email'
        theme='blueTheme'
        onChange={(e) => handleEventChange(e)}
      />
    );
  }

  const handleClickAdd = () => {
    const walletId = wallet._id;
    props.addCustomer(
      walletId,
      props,
      (
        message: string,
        links: [string],
        subscriptionMessage: string,
        customerId: string
      ) => {
        props.addCustomerInitialState();
        props.history.push(`/inscription`, {
          subscriptionMessage: subscriptionMessage,
          appLinks: links,
          lastName: lastName,
          firstName: firstName,
          customerId,
          walletId,
        });
      }
    );
  };

  if (walletLoading) {
    return (
      <div className={classes.circularProgressContainer}>
        <CircularProgress size={30} className={classes.circularProgress} />
      </div>
    );
  } else if (error) {
    return (
      <Wrapper>
        <span className='text-error'>{error}</span>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      background={
        wallet && wallet.backgroundColor && wallet.backgroundColor.hex
      }
    >
      <div>
        <div className='logo-container'>
          <img src={`${URL_IMAGE}/${wallet.logoPictureUrl}`} alt='logo' />
        </div>
        <span className='wallet-name'>{wallet.name}</span>

        <div className='Inputs-container'>
          <InputLabel
            label='Prénom'
            name='firstName'
            value={firstName}
            error={firstNameError}
            placeholder='Prénom'
            theme='blueTheme'
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            label='Nom'
            name='lastName'
            value={lastName}
            error={lastNameError}
            theme='blueTheme'
            placeholder='Nom'
            onChange={(e) => handleEventChange(e)}
          />
          {communicationChannels == 'phoneNumber' && phoneInput()}
          {communicationChannels == 'emailAdress' && emailInput()}
          {communicationChannels == 'all' && [emailInput(), phoneInput()]}

          {showBarcode && (
            <InputLabel
              label='Code à barre'
              name='barcode'
              value={barcode}
              error={barcodeError}
              theme='blueTheme'
              placeholder='Code à barre'
              onChange={(e) => handleEventChange(e)}
            />
          )}
        </div>
        <div className='Terms-description'>
          En cliquant sur Ajouter, vous acceptez{' '}
          <Link to='/terms'>la présente politique de confidentialité.</Link>
        </div>
        <div className='error'>{emailBarcodeError}</div>
        <Button
          styled=''
          theme='blueTheme'
          onClick={handleClickAdd}
          margin='10px'
        >
          {loading ? <CircularProgress size={20} color='inherit' /> : 'Ajouter'}
        </Button>
      </div>
    </Wrapper>
  );
};

type WrapperProps = {
  background?: string;
};

const Wrapper = styled('div')<WrapperProps>`
  ${(props) => addCustomerStyles(props)}
`;

const mapStateToProps = ({ customer, settings }: RootState) => {
  const showBarcode = settings.showBarcode;
  const {
    wallet,
    firstName,
    lastName,
    email,
    phoneNumber,
    barcode,
    //errors
    firstNameError,
    lastNameError,
    emailError,
    barcodeError,
    phoneNumberError,
    emailBarcodeError,
    //loading
    loading,
    walletLoading,

    error,
  } = customer;
  const { communicationChannels } = settings;

  return {
    wallet,
    firstName,
    lastName,
    email,
    phoneNumber,
    barcode,
    //errors
    firstNameError,
    lastNameError,
    emailError,
    phoneNumberError,
    barcodeError,
    emailBarcodeError,
    //loading
    loading,
    walletLoading,
    showBarcode,
    communicationChannels,
    error,
  };
};

export const connector = connect(mapStateToProps, {
  addCustomerPropsChanged,
  getWalletDetail,
  addCustomer,
  addCustomerInitialState,
  getWalletSettings,
});

export default connector(AddCustomer);
