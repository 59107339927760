import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';

//Components
import CustomerButton from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import ModalFullscreen from '../../components/ModalFullscreen';
import WalletForm from '../../components/Forms/WalletForm';
import EmptyData from '../../components/EmptyData';
import WalletCard from '../../components/walletCard';

//Actions
import {
  getWalletsList,
  setWallet,
  setNewWallet,
  walletFormInitalState,
  deleteWallet,
} from '../../actions';
import { RootState } from '../../reducers';
import { WalletsProps } from './wallets.types';
import { WalletApiResponse } from '../../apis/ApiTypes';
import { getSettings } from '../../apis/settingsApi';

//Images
import emptyState from '../../assets/images/emptyState.svg';

import { walletsStyles, WalletsStylesProps } from './wallets.styles';

const Wallets: React.FC<WalletsProps> = (props) => {
  const history = useHistory();
  const { walletsList, loading } = props;
  const rowsperpage = 20;

  const [page, setPage] = React.useState(0);
  const [redirect, setredirect] = React.useState<boolean>(false);
  const [listData, setListData] = React.useState<any[]>([]);

  React.useEffect(() => {
    props.getWalletsList();
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    setListData(walletsList);
  }, [walletsList]);

  const handleClickAddWallet = () => {
    props.history.push(`/wallets/create`);
    props.setNewWallet();
  };

  const handleClickEditWallet = async (wallet: WalletApiResponse) => {
    props.history.push(`/wallets/edit/${wallet._id}`);
    props.setWallet(wallet);
  };

  const handleClickDeleteWallet = (walletId: string) => {
    props.deleteWallet(walletId);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const Result = event.target.value
      ? walletsList.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(event.target.value.toLocaleLowerCase())
        )
      : walletsList;
    setListData(Result);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };

  const calculatePagesCount = (pageSize: number, totalCount: number) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  return (
    // <SideBar>
    <React.Fragment>
      {/* <Header history={props.history}></Header> */}
      <WalletsWrapper
        loading={loading.toString()}
        dataLength={walletsList.length}
      >
        <div>
          <h1>Liste des Wallets</h1>
          <Box position='relative' width='60%'>
            <SearchIcon className='icon' />
            <Input placeholder='Search' onChange={handleSearch} />
          </Box>
          <CustomerButton
            height={50}
            styled='primary'
            onClick={handleClickAddWallet}
          >
            Nouveau
          </CustomerButton>
        </div>
        <div className='wallet-list-style'>
          {/* <ModalFullscreen open={open} onClose={() => setOpen(!open)}>
            <WalletForm onSaveChanged={() => setOpen(!open)}></WalletForm>
          </ModalFullscreen> */}
          <EmptyData
            data={walletsList}
            loading={loading}
            message='La liste des wallets est vide'
            image={emptyState}
          >
            {listData
              ?.slice(page * rowsperpage, page * rowsperpage + rowsperpage)
              .map((wallet) => (
                <WalletCard
                  key={wallet._id}
                  wallet={wallet}
                  onClickEdit={handleClickEditWallet}
                  onClickDelete={handleClickDeleteWallet}
                  onClickStats={() =>
                    props.history.push(`/wallets/statisitics/${wallet._id}`)
                  }
                  onClick={() =>
                    props.history.push(`/wallets/details/${wallet._id}`)
                  }
                  loading={loading}
                ></WalletCard>
              ))}
          </EmptyData>
        </div>
        {/* <Route path={`${path}/:walletId`} component={WalletDetail} /> */}
        <Box display='flex' justifyContent='flex-end' marginTop='auto'>
          <Pagination
            count={calculatePagesCount(rowsperpage, listData?.length || 0)}
            variant='outlined'
            shape='rounded'
            onChange={handleChangePage}
          />
        </Box>
      </WalletsWrapper>
    </React.Fragment>
    // </SideBar>
  );
};

const WalletsWrapper = styled('div')<WalletsStylesProps>`
  ${(props) => walletsStyles(props)}
`;

const mapStateToProps = ({ wallets }: RootState) => {
  const { walletsList, loading } = wallets;

  return {
    walletsList,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  getWalletsList,
  setWallet,
  setNewWallet,
  deleteWallet,
  walletFormInitalState,
});

export default connector(Wallets);
