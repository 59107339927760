import React from 'react';
import loading from '../../assets/images/loading.gif';

const LoadingIndicator = () => {
  return (
    <div className='loadingIndicator'>
      <img
        src={loading}
        alt='loading...'
        height='auto'
        width='100%'
        style={{
          display: 'block',
          margin: 'auto',
          maxWidth: '354px',
          marginBottom: '39px',
        }}
        id='image-section'
      />
    </div>
  );
};

export default LoadingIndicator;
