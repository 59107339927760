import axios from 'axios';
import { StatisticsDetailResponseAPI } from '../modules/Statistics/stats.types';
import { StatsSMSresponse } from './ApiTypes';
import { URL } from './config';

export const getSMSstats = (walletId: string, startDate: any, endDate: any) => {
  let requestURL = `${URL}/stats/wallets/${walletId}/sms`;
  if (!walletId) requestURL = `${URL}/stats/sms`;

  return axios
    .get(requestURL, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
    .then(({ data }: { data: StatsSMSresponse }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getEmailStats = (
  walletId: string,
  startDate: any,
  endDate: any
) => {
  let requestURL = `${URL}/stats/wallets/${walletId}/email`;
  if (!walletId) requestURL = `${URL}/stats/email`;

  return axios
    .get(requestURL, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSMSstatsDetails = (
  walletId: string,
  startDate: any,
  endDate: any,
  type: string
) => {
  let requestURL = `${URL}/stats/${walletId}/sms/details/${type}`;

  return axios
    .get(requestURL, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
    .then(({ data }: { data: StatisticsDetailResponseAPI }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getClientStatsDetails = (
  walletId: string,
  startDate: any | null = null,
  endDate: any | null = null
) => {
  let requestURL = `${URL}/stats/wallets/${walletId}/clients`;

  if (!walletId) requestURL = `${URL}/stats/clients`;

  return axios
    .get(requestURL, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
    .then(({ data }: { data: StatisticsDetailResponseAPI }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
