import { css } from 'styled-components';

export const subscriptoinPageAppleStyles = () => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    align-content: center;
    flex-direction: column;
    width:100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;

    hr {
      margin-top: 15px;
      width: 184px;
      align-self: center;
      margin-bottom: 30px;
    }
    .errorMessage {
      color: red;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: center;
      width:100%;

    }
    .saveToWalletButton {
      width: 193px;
      height: 61px;
    }
.image{
  display:flex;
  align-self : center;
}
    html {
      box-sizing: border-box;
      font-size: 62.5%;
      line-height: 1.15; /* 1 */
      -webkit-text-size-adjust: 100%; /* 2 */
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
      margin: 0;
      padding: 0;
    }
    img {
      object-fit: cover;
      margin-top: 33px;
      cursor: pointer;
    }
    .header {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      font-size: 14px;
      line-height: 180%;
      color: #737487;
    }
    span {
      margin-top: 11px;
      margin-bottom: 24px;
    }
    h5 {
      color: #555555;
      font-size: 20px;
      font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
      line-height: 120%;
      text-align: center;
      direction: ltr;
      font-weight: normal;
      letter-spacing: normal;
      margin-top: 0;
      margin-bottom: 0;
      margin-top: 28px;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 32px;
      line-height: 180%;
      color: #737487;
    }
    .divider {
      padding-bottom: 22px;
    }
    .notice {
      color: grey;
    }

    .divider > img{
        display: block;
        width: 100%;
      }
    }

  `;
};
