import axios from 'axios';
import { URL } from './config';
import { WalletApiResponse } from './ApiTypes';

export const getWallets = () => {
  const requestURL = `${URL}/wallets/`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<WalletApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
