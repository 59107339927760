import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as dotenv from 'dotenv';
import { initializeFirebase } from './push-notification';

import { IntlProvider } from 'react-intl';
import enMessages from '../src/translation/messages.en';
import frMessages from '../src/translation/messages.fr';
import deMessages from '../src/translation/messages.de';
import { getBrowserLanguage } from './utils/language';

const locale = 'fr' || getBrowserLanguage() || localStorage.getItem('language');

const messages: any = {
  en: enMessages,
  fr: frMessages,
  de: deMessages,
};

dotenv.config();
// localStorage.removeItem('token');
// localStorage.removeItem('user');

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={messages[`${locale}`]}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

//   initialize firebase
initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
