import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { walletCardStyles } from './walletCard.styles';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../Modal';
import Button from '../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { URL_IMAGE } from '../../apis/config';
import { WalletApiResponse } from '../../apis/ApiTypes';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    color: '#fff',
    padding: 0,
  },
});

type WalletCardProps = {
  wallet: WalletApiResponse;
  loading: Boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickEdit: (wallet: WalletApiResponse) => void;
  onClickDelete: (walletId: string) => void;
  onClickStats: () => void;
};
const WalletCard: React.FC<WalletCardProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);
  const [is_loading, setIsLoading] = React.useState<any>(null);

  const {
    wallet,
    loading,
    onClick,
    onClickEdit,
    onClickDelete,
    onClickStats,
  } = props;
  const classes = useStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Wrapper className='wallet-card' background={wallet.backgroundColor.hex}>
      <div className='wallet-card-menu'>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          className={classes.root}
          onClick={handleClick}
          style={{
            background: '#ff5078',
            padding: '2px',
          }}
        >
          <MoreHorizIcon fontSize='small' />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClick={handleClose}
        >
          <MenuItem
            onClick={() => {
              onClickEdit(wallet);
            }}
          >
            Modifier
          </MenuItem>{' '}
          <MenuItem
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Supprimer
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickStats();
            }}
          >
            Statistiques
          </MenuItem>
        </Menu>
        <ConfirmationModal open={is_open}>
          <div className='modal-cntr'>
            <span style={{ marginTop: '15 px' }}>
              Voulez vous Supprimer Wallet de {wallet.name} ?{' '}
            </span>
            {is_open ? (
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  styled='secondary'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  annuler
                </Button>

                <Button
                  styled='primary'
                  onClick={() => {
                    onClickDelete(wallet._id);
                  }}
                >
                  {is_loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    ''
                  )}
                  confirmer
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </ConfirmationModal>
      </div>
      <div className='wallet-card-img' onClick={onClick}>
        <img
          src={`${URL_IMAGE}/${wallet.logoPictureUrl}?${new Date().getTime()}`}
          alt='logo'
        />
      </div>
      <div className='card-info'>
        <p> Wallet de {wallet.name}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ background: string }>`
  ${(props) => walletCardStyles(props)}
`;

export default WalletCard;
