import { css } from 'styled-components';

export type OffersStylesProps = {
  loading: string;
};

export const offersStyles = (props: OffersStylesProps) => {
  let flex = 0;
  let justifyContent = 'flex-start';

  return css`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */

    .div-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    .div-input-filter {
        min-width: 400px;
        margin-right: 15px;
      }

      .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        background-color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    } 

    .filter-button{
        display: flex;
        position: absolute;
        left: 290px;
        width: 100px;
        align-items: center;
        justify-content: space-between;
        background: #4914F4;
        height: 46px;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
        padding: 0 10px;
        transition: all 0.3s ease;
        :hover {
          cursor: pointer;
          background: #6438f5;
        }
        > h3{
          /* font-family: Poppins; */
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: white;
          width: 80px;
        }
      }
      .filter-opened{
        transform: rotate(180deg)
      }

      .select-input {
        width: 150px;
        visibility: hidden;
        position: absolute;
        left: 260px;
        top: 70px;
      }
    .div-search {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 15px;
      /* width: 100%; */
      .div-search-inputs{
        display:flex;
        flex: 1;  
       }

      .div-search-date {
        display: flex;
        gap: 50px
        /* position: relative; */
        /* .div-checkbox {
          position: absolute;
          bottom: -35px; */
        }
      }
    }
   
    .div-list {
      margin: 15px 0;
      display: flex;
      flex: ${flex};
      justify-content: ${justifyContent};
      flex-wrap: wrap;
      /* min-height: 500px; */
      align-items: center;
    }


    cardContent: {
      marginTop: '10px',
    },
    media: {
      height: 185,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },

    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },

    .menuIcon: {
      fill: '#ffffff',
    },
  
    .cardClient: {
      position: 'relative',
      width: 223,
      marginRight: '15px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
    }
  `;
};
