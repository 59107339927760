import React from 'react';
import styled from 'styled-components';
import { buttonStyles } from './button.styles';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  height: number;
  onClick: () => void;
  styled: string;
  margin: string;
};

const Button = styled.button<any>`
  ${(props) => buttonStyles(props)}
`;

export default Button;
