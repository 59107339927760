import { isEmpty, isEqual } from '../../utils/validations';
import { SettingsState } from './settings.types';

export const validateFieldsSettingsUpdatePassword = (state: SettingsState) => {
  let newState: SettingsState = Object.assign({}, state);
  if (isEmpty(newState.oldPassword)) {
    newState.oldPasswordError = 'Ancien mot de passe est obligatoire.';
  } else {
    newState.oldPasswordError = '';
  }

  if (isEmpty(newState.newPassword)) {
    newState.newPasswordError = 'Nouveau mot de passe est obligatoire.';
  } else if (newState.newPassword.length < 4) {
    newState.newPasswordError =
      'Le mot de passe doit contenir au moins 4 caractères.';
  } else {
    newState.newPasswordError = '';
  }

  if (isEmpty(newState.confirmPassword)) {
    newState.confirmPasswordError = 'Confirm mot de passe est obligatoire.';
  } else if (!isEqual(newState.newPassword, newState.confirmPassword)) {
    newState.confirmPasswordError =
      'La confirmation ne correspond pas au nouveau mot de passe.';
  } else {
    newState.confirmPasswordError = '';
  }

  let valid = false;
  if (
    newState.newPasswordError === '' &&
    newState.confirmPasswordError === ''
  ) {
    valid = true;
  }
  return { newState, valid };
};
