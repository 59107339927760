import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useIntl } from 'react-intl';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export interface AlertDialogSlideProps {
  open: boolean;
  message: string;
  handleClose: () => void;
}

export default function AlertDialogSlide(props: AlertDialogSlideProps) {
  const { open, handleClose, message } = props;
  const intl = useIntl();

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {intl.formatMessage({ id: 'notification.insctructions.title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'notification.insctructions.description',
                }),
              }}
            ></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {intl.formatMessage({
              id: 'notification.insctructions.confirm',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
