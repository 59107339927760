import React, { useEffect } from 'react';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { walletFormPropsChanged } from '../../actions';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const passKinds = [
  'eventTicket',
  'transit',
  'boardingPass',
  'cinemaPass',
  'hotelPass',
  'coupon',
  'offer',
];

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PassPermissionSelector: React.FC<any> = (props: any) => {
  const { allowedPasses, action } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [selectedPasses, setselectedPasses] = React.useState<string[]>(
    allowedPasses
  );
  useEffect(() => {
    setselectedPasses(allowedPasses);
    if (allowedPasses.includes('all')) setselectedPasses(passKinds);
  }, [allowedPasses]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.walletFormPropsChanged('allowedPasses', event.target.value);
    setselectedPasses(event.target.value as string[]);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId='demo-mutiple-chip-label'
          id='demo-mutiple-chip'
          multiple
          value={selectedPasses}
          onChange={handleChange}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {passKinds.map((kind) => (
            <MenuItem
              key={kind}
              value={kind}
              style={getStyles(kind, selectedPasses, theme)}
            >
              {kind}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
const mapStateToProps = ({ wallet }: RootState) => {
  return {
    allowedPasses: wallet.allowedPasses,
  };
};
export const connector = connect(mapStateToProps, { walletFormPropsChanged });

export default connector(PassPermissionSelector);
