import { css } from 'styled-components';

export const getColorPickerStyles = (props: any) => {
  return css`
    margin: 8px 0;
    .label {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #828282;
      margin: 5px;
    }
    .div-contain {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      width: 220px;
      margin: 5px 0;
      padding: 6px 6px 6px 15px;
      background: #f8f6fe;
      box-sizing: border-box;
      border-radius: 20px;
      @media (max-width: 850px) {
        width: 230px;
      }
    }
    .color {
      width: 42px;
      height: 42px;
      border-radius: 18px;
      background: ${props.color};
      border: 2px solid #ffffff;
    }
    .swatch {
      cursor: pointer;
    }
    .popover {
      /* bottom: 10%;a */
      position: absolute;
      margin-top: 360px;
      z-index: 2;
    }
    .cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: '0px';
    }
    .text-error {
      /* align-self: flex-start; */
      font-size: 14px;
      color: #ed0723;
      margin-bottom: 8px;
      margin: 5px;
    }
  `;
};
