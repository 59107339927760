import React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStyles } from './table.styles';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
//Images
import emptyState from '../../assets/images/emptyState.svg';

type RowProps = {
  row: any;
  handleClickHistory: (id: string) => void;
};

const Row: React.FC<RowProps> = (props) => {
  const { row, handleClickHistory } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const renderHistory = () => {
    const { history, loading }: { history: Array<any>; loading: boolean } = row;
    if (loading) {
      return (
        <div className={classes.historyContainer}>
          <CircularProgress
            size={30}
            className={classes.circularProgressStyle}
          />
        </div>
      );
    } else if (history.length === 0 && !loading) {
      return (
        <div className={classes.historyContainer}>
          <div className={classes.imgContainer}>
            <img
              src={emptyState}
              className={classes.imgStyle}
              alt='empty-state'
            />
          </div>
          <p className={classes.textEmpty}>
            La liste d&apos;historique est vide
          </p>
        </div>
      );
    }

    return (
      <Table className={classes.historyRow} size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell}>Date</TableCell>
            <TableCell className={classes.tableHeaderCell}>Gain</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.historyTableBody}>
          {history.map((historyRow, index) => (
            <TableRow key={historyRow._id}>
              <TableCell
                className={
                  index === history.length - 1
                    ? classes.tableContentCell +
                      ' ' +
                      classes.LastHistoryTableElement
                    : classes.tableContentCell
                }
                component='th'
                scope='row'
              >
                {moment(historyRow.createdAt).format('MMMM DD YYYY, h:mm:ss ')}
              </TableCell>
              <TableCell
                className={
                  index === history.length - 1
                    ? classes.tableContentCell +
                      ' ' +
                      classes.LastHistoryTableElement
                    : classes.tableContentCell
                }
              >
                {historyRow.gain}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  let source = null;
  switch (row.source) {
    case 'internal':
      source = 'interne';
      break;
    case 'external':
      source = 'externe';
      break;
    case 'crm':
      source = 'crm';
      break;
    default:
      source = 'interne';
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            size='small'
            aria-label='expand row'
            onClick={() => {
              if (!open) {
                handleClickHistory(row._id);
              }

              setOpen(!open);
            }}
          >
            {open ? (
              <KeyboardArrowUpIcon color='secondary' />
            ) : (
              <KeyboardArrowDownIcon color='secondary' />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          className={classes.tableContentCell}
          component='th'
          scope='row'
        >
          {row._id}
        </TableCell>
        <TableCell className={classes.tableContentCell}>
          {row.firstName}
        </TableCell>
        <TableCell className={classes.tableContentCell}>
          {row.lastName}
        </TableCell>
        <TableCell className={classes.tableContentCell}>
          {moment(row.createdAt).format('LL')}
        </TableCell>
        <TableCell className={classes.tableContentCell}>{row.gain}</TableCell>
        <TableCell className={classes.tableContentCell}>{row.email}</TableCell>
        <TableCell className={classes.tableContentCell}>
          {row.phoneNumber}
        </TableCell>
        <TableCell className={classes.tableContentCell}>{source}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box marginLeft={10}>
              <h3 className={classes.HistoryTitle}>Historique</h3>
              {renderHistory()}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

type CollapsibleTableProps = {
  data: Array<any>;
  handleClickHistory: (id: string) => void;
};

const CollapsibleTable: React.FC<CollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const { data, handleClickHistory } = props;
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '24px 16px' }} />
            <TableCell className={classes.tableHeaderCell}>
              ID (Code Barre)
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>Prénom</TableCell>
            <TableCell className={classes.tableHeaderCell}>Nom</TableCell>
            <TableCell className={classes.tableHeaderCell}>
              Enregistré le
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>Gains</TableCell>
            <TableCell className={classes.tableHeaderCell}>Email</TableCell>
            <TableCell className={classes.tableHeaderCell}>
              Numéro de téléphone
            </TableCell>
            <TableCell className={classes.tableHeaderCell}>Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row._id}
              row={row}
              handleClickHistory={handleClickHistory}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
