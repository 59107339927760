import { css } from 'styled-components';

export const walletCardStyles = (props: { background: string }) => {
  return css`
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    align-items: center;
    /* align-items: center; */
    padding: 30px 20px 20px 20px;
    box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
    border-radius: 15px;
    transition: all 0.5s ease;
    border: 1px solid #fff;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 20px 2px rgba(255, 80, 120, 0.2);
      border: 1px solid #ff5078;
    }
    @media (max-width: 750px) {
      /* flex: 1; */
    }

    .wallet-card-menu {
      /* z-index: 12; */
      position: relative;
      right: -110px;
      top: -20px;
      transform: rotate(90deg);
    }

    .wallet-card-img {
      /* z-index: 1; */
      position: relative;
      cursor: pointer;
      width: 200px;
      height: 200px;
      /* box-shadow: 0px 0px 16px 4px rgb(0 0 0 / 8%); */
      border-radius: 15px;
      border: 1px solid #cdcdcd;
      margin-bottom: 12px;
      margin-top: -24px;
      overflow: hidden;
      background: #fff;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .card-info {
      text-align: center;

      p {
        margin: 5px 0 0 0;
        width: 220px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #404040;
        word-break: normal;
      }
    }
    @media (max-width: 1300px) {
      padding: 30px 15px 20px 15px;
      border-radius: 10px;
      .wallet-card-img {
        width: 160px;
        height: 160px;
      }
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
      .wallet-card-menu {
        right: -85px;
        top: -25px;
      }
      p {
        font-size: 14px !important;
        width: 170px !important;
      }
    }
  `;
};
