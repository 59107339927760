import React from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom';
import SideBar from '../../components/Drawer';
import Wallets from '../../modules/Wallets';
import WalletDetail from '../../modules/WalletDetail';
import { useStyles } from './WalletsRouter.styles';
import NotFound from '../../modules/NotFound';
import WalletForm from '../../modules/WalletForm';
import Statistics from '../../modules/Statistics';

//Modules

const WalletsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar>
        <Switch>
          <Route path={`${path}/`} component={Wallets} exact />
          <Route path={`${path}/create`} component={WalletForm} exact />
          <Route path={`${path}/edit/:walletId`} component={WalletForm} />
          <Route path={`${path}/details/:walletId`} component={WalletDetail} />
          <Route
            path={`${path}/statisitics/:walletId`}
            component={Statistics}
          />

          <Route path={`${path}/*`} component={NotFound} exact />
        </Switch>
      </SideBar>
    </div>
  );
};

export default WalletsRouter;
