import {
  LOGINING_INITIAL_STATE,
  NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED,
} from '../actions/types';

const INITIAL_STATE: any = {
  loading: '',
  pushToken: '',
};

export default (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case NOTIFICATION_SUBSCRIBTION_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case LOGINING_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
