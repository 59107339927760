import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 223,
      marginRight: '15px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
      marginTop: '38px',
    },
    cardContent: {
      marginTop: '10px',
    },
    media: {
      height: 185,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },

    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '240px',
      top: '15px',
    },
    totalCard: {
      width: '332px  !important',
    },
    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
  })
);
