import { css } from 'styled-components';
import { opacify } from 'polished';

export const inputStylesNew = (props: any) => {
  let border = 'blue';
  let shadow = 'rgba(0, 0, 0, 0.4)';
  let margin = props.margin ? props.margin : '5px 0';
  let width = props.width ? `${props.width}px` : '100%';

  return css`
    height: 48px;
    min-height: 48px;
    width: ${width};
    color: rgb(70, 70, 90);
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 100;
    background: #f5f8fe;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 15px;
    margin: ${margin};
    outline: none;
    transition: all 0.2s ease-in-out;
    ::placeholder {
      color: #c0c0c0;
      font-size: 14px;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
      font-size: 14px;
    }
    :focus {
      border: 1px solid blue 4a;
      box-shadow: 0 0 3px 3px #518aed 24;
      background: white;
      color: #404040 !important;
      outline: 1px solid #518aed !important;
    }
  `;
};
