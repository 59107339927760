import { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const addCustomerStyles = (props: { background?: string }) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    Button {
      background: 'linear-gradient(90deg, #4914F4 9.06%, #9BBAFF 100%)';
      border-radius: '3.98506px';
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 5%;
      width: 90%;
      max-width: 450px;
      padding: 20px 0;
      border-radius: 15px;
      /* height: 520px; */
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    .logo-container {
      margin: 15px;
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      background-color: ${props.background};
      img {
        width: 100%;
        height: 100%;
        /* object-fit: cover; */
      }
    }
    .error {
      color: red;
    }
    .wallet-name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: #000;
      letter-spacing: 0.3px;
    }

    .text-error {
      font-size: 24px;
      color: red;
    }
    .Inputs-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      > div {
        width: 100% !important;
      }
    }
    .Terms-description {
      font-size: 14px;
      padding: 0px 40px 10px 50px;
      margin: auto;
    }

    Link {
      color: red;
    }

    a:visited {
      color: #4646f7;
    }
  `;
};

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '15px',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  circularProgressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  circularProgress: {
    color: '#ff5078',
  },
}));
